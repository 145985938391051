import { LoadingOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import { InsightsCampaignsSummaryInterface } from "../../../../types/events.interfaces";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";
import CommissionTooltip from "../Tooltips/CommissionTooltip";
import SalesTooltip from "../Tooltips/SalesTooltip";

interface Props {
  loading: boolean;
  campaignsInsights: InsightsCampaignsSummaryInterface[];
}

const CampaignDailySummary = ({ loading, campaignsInsights }: Props) => {
  const impressionsSum = (): number => {
    let impressions = 0;
    campaignsInsights.forEach((value) => {
      impressions += value.distinctOpen;
    });
    return impressions;
  };

  const salesSum = (): number => {
    let sales = 0;
    campaignsInsights.forEach((value) => {
      sales += value.totalSalesCount;
    });
    return sales;
  };

  const commisionSum = (): number => {
    let commision = 0;
    campaignsInsights.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision;
  };

  const commisionAvg = (): number => {
    let commision = 0;
    campaignsInsights.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision > 0 ? commision / campaignsInsights.length : commision;
  };

  const epiAvg = (): number => {
    let epi = 0;
    campaignsInsights.forEach((value) => {
      epi += checkForZero(value.distinctOpen, value.totalCommission)
        ? value.totalCommission / value.distinctOpen
        : 0;
    });
    return epi > 0 ? epi / campaignsInsights.length : epi;
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  interface DataType {
    key: React.Key;
    name: string;
    impressions: number;
    sales: number;
    commision: string;
    epi: string;
    transactionsWebPendingCount: number;
    transactionsWebApprovedCount: number;
    transactionsAutoEmailPendingCount: number;
    transactionsAutoEmailApprovedCount: number;
    transactionsManualEmailPendingCount: number;
    transactionsManualEmailApprovedCount: number;
    transactionsSharePendingCount: number;
    transactionsShareApprovedCount: number;
    totalWebPendingAmount: number;
    totalWebApprovedAmount: number;
    totalAutoEmailApprovedAmount: number;
    totalAutoEmailPendingAmount: number;
    totalManualEmailPendingAmount: number;
    totalManualEmailApprovedAmount: number;
    totalSharePendingAmount: number;
    totalShareApprovedAmount: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Campaign",
      dataIndex: "name",
      sorter: (a: DataType, b: DataType) => a.name.localeCompare(b.name),
      fixed: "left",
      width: 170,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      sorter: (a: DataType, b: DataType) => a.impressions - b.impressions,
      align: "center",
      width: 150,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      sorter: (a: DataType, b: DataType) => a.sales - b.sales,
      align: "center",
      width: 120,
      render: (sales: number, record: DataType) => (
        <span>
          {sales}
          {sales > 0 && <SalesTooltip record={record} />}
        </span>
      ),
    },
    {
      title: "Commission",
      dataIndex: "commision",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.commision) - stripAndReturnNumber(b.commision),
      align: "center",
      width: 150,
      render: (commision: string, record: DataType) => (
        <span>
          {commision}
          <span>
            {commision !== "£0.00" && <CommissionTooltip record={record} />}
          </span>
        </span>
      ),
    },
    {
      title: "EPI",
      dataIndex: "epi",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epi) - stripAndReturnNumber(b.epi),
      align: "center",
      width: 100,
    },
  ];

  const data = campaignsInsights?.map(
    (data: InsightsCampaignsSummaryInterface, index: number) => {
      return {
        key: index.toString(),
        name: data.campaign?.oname,
        impressions: data.distinctOpen,
        sales: data.totalSalesCount,
        commision: `${
          data?.campaign?.currencySymbol
        }${data.totalCommission.toFixed(2)}`,
        epi: `${data?.campaign?.currencySymbol}${
          checkForZero(data.distinctOpen, data.totalCommission)
            ? (data.totalCommission / data.distinctOpen).toFixed(2)
            : "0.00"
        }`,
        transactionsWebPendingCount: data.transactionsWebPendingCount,
        transactionsWebApprovedCount: data.transactionsWebApprovedCount,
        transactionsAutoEmailPendingCount:
          data.transactionsAutoEmailPendingCount,
        transactionsAutoEmailApprovedCount:
          data.transactionsAutoEmailApprovedCount,
        transactionsManualEmailPendingCount:
          data.transactionsManualEmailPendingCount,
        transactionsManualEmailApprovedCount:
          data.transactionsManualEmailApprovedCount,
        transactionsSharePendingCount: data.transactionsSharePendingCount,
        transactionsShareApprovedCount: data.transactionsShareApprovedCount,
        totalWebPendingAmount: data.totalWebPendingAmount,
        totalWebApprovedAmount: data.totalWebApprovedAmount,
        totalAutoEmailApprovedAmount: data.totalAutoEmailApprovedAmount,
        totalAutoEmailPendingAmount: data.totalAutoEmailPendingAmount,
        totalManualEmailPendingAmount: data.totalManualEmailPendingAmount,
        totalManualEmailApprovedAmount: data.totalManualEmailApprovedAmount,
        totalSharePendingAmount: data.totalSharePendingAmount,
        totalShareApprovedAmount: data.totalShareApprovedAmount,
      };
    }
  );

  return (
    <>
      <div className="widget-description"></div>

      <div className="widget-table">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
            scroll={{ x: undefined, y: 380 }}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">{impressionsSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <span className="bold">{salesSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <div className="bold">
                      {campaignsInsights?.[0].campaign?.currencySymbol}
                      {commisionSum().toFixed(2)}
                    </div>
                    <div className="font-xsmall">
                      {campaignsInsights?.[0].campaign?.currencySymbol}
                      {commisionAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <div className="bold">
                      {campaignsInsights?.[0].campaign?.currencySymbol}
                      {commisionSum() > 0 && impressionsSum() > 0
                        ? (commisionSum() / impressionsSum()).toFixed(2)
                        : "0.00"}
                    </div>
                    <div className="font-xsmall">
                      {campaignsInsights?.[0]?.campaign?.currencySymbol}
                      {epiAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>

      <div className="widget-footer"></div>
    </>
  );
};

export default CampaignDailySummary;
