import { Button, DatePicker, Dropdown, message } from "antd";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth.hook";
import useAxios from "../../hooks/useAxios.hook";
import usePage from "../../hooks/usePage.hook";
import { DropDown } from "../../types/global.interfaces";
import "./css/insight.scss";
import { stringDateToMoment } from "../../utility/date";
import {
  DailySalesChartSummaryInterface,
  InsightsCampaignsSummaryInterface,
  InsightsHostPartners,
} from "../../types/events.interfaces";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import InsightSummaryWidgets from "./Insight-summary-widgets.component";
import { RangePickerProps } from "antd/lib/date-picker";
import { PlacementStage } from "../../types/templates.interfaces";

const { RangePicker } = DatePicker;

const filterByDate: DropDown[] = [
  { label: "Last week", key: "lastweek" },
  { label: "This week", key: "thisweek" },
  { label: "Last month", key: "lastmonth" },
  { label: "This month", key: "thismonth" },
  { label: "Last 90 days", key: "last90days" },
  { label: "Yesterday", key: "yesterday" },
  { label: "Today", key: "today" },
  { label: "Custom", key: "custom" },
];

const InsightNew = ({
  activeClass,
}: {
  activeClass: PlacementStage | "all";
}) => {
  const { setPage } = usePage();
  const { auth } = useAuth();
  const { company } = useAuth();
  const { myAxios } = useAxios();
  const [campaignsInsights, setCampaignsInsights] =
    useState<InsightsCampaignsSummaryInterface[]>();
  const [partnersOffers, setPartnersOffers] = useState<InsightsHostPartners>();
  const [campaignsDailySales, setCampaignsDailySales] =
    useState<DailySalesChartSummaryInterface[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(true);

  const [currentDateFilterType, setDateFilterType] = useState<DropDown>({
    label: "Yesterday",
    key: "yesterday",
  });

  const [filters, setFilters] = useState({
    dateFilterType: "yesterday",
    fromDate: "",
    endDate: "",
  });

  const fetchCampaignDaily = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `insights/host/aggregated/daily/${companyId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setCampaignsDailySales(response.data.result.data);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchPartnersOffers = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `insights/host/aggregated/partners/${companyId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setPartnersOffers(response.data.result);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchCampaigns = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `/insights/host/aggregated/campaigns/${companyId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setCampaignsInsights(response.data.result.data);
    } else {
      message.error("Failed to get Campaings", 1);
    }
  };

  useEffect(() => {
    setPage({ title: <>Insight</> });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (company && auth) fetchCampaigns(company.company_Id);
    setStart(true);
    // eslint-disable-next-line
  }, [company, auth, activeClass]);

  useEffect(() => {
    if (company && start) {
      fetchPartnersOffers(company.company_Id);
      fetchCampaignDaily(company.company_Id);
      setStart(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeDateFilterType = (label: string) => {
    const newDateFilter = filterByDate.find((value) => value.key === label);
    const isCustom = label === "custom";
    isCustom &&
      setPartnersOffers(
        (prevState) => prevState && { ...prevState, fromDate: "", toDate: "" }
      );
    if (newDateFilter) {
      setDateFilterType(newDateFilter);
      setFilters({
        ...filters,
        dateFilterType: newDateFilter.key,
        fromDate: "",
        endDate: "",
      });
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > moment().add(-1, "day");
  };

  const handleChangeDateStartEnd = (
    value: RangeValue<moment.Moment> | null
  ) => {
    if (value) {
      const fromDate = value[0]?.format("YYYY-MM-DD");
      const endDate = value[1]?.format("YYYY-MM-DD");
      if (fromDate && endDate) {
        setFilters({
          ...filters,
          dateFilterType: "custom",
          fromDate: fromDate,
          endDate: endDate,
        });
        setDateFilterType({ label: "Custom", key: "custom" });
      }
    } else {
      setFilters({
        ...filters,
        dateFilterType: "thisweek",
        fromDate: "",
        endDate: "",
      });
      setDateFilterType({ label: "This week", key: "thisweek" });
    }
  };

  const handleSearchCustomFilters = () => {
    if (company) {
      fetchCampaignDaily(company.company_Id);
      fetchPartnersOffers(company.company_Id);
      fetchCampaigns(company.company_Id);
    }
  };

  return (
    <>
      <div className="layout-options">
        <div className="layout-actions"></div>
        <div className="filters">
          <Dropdown
            className="min150 mright20"
            menu={{
              items: filterByDate,
              selectable: true,
              defaultSelectedKeys: ["yesterday"],
              onClick: (data) => {
                handleChangeDateFilterType(data.key);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span> {currentDateFilterType?.label}</span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>

          <RangePicker
            value={[
              filters.fromDate
                ? stringDateToMoment(filters.fromDate)
                : partnersOffers?.fromDate
                ? stringDateToMoment(partnersOffers?.fromDate)
                : null,
              filters.endDate
                ? stringDateToMoment(filters.endDate)
                : partnersOffers?.toDate
                ? stringDateToMoment(partnersOffers?.toDate)
                : null,
            ]}
            className="filter-date"
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            disabled={currentDateFilterType.key !== "custom"}
            onChange={handleChangeDateStartEnd}
          />

          <Button
            type="primary"
            className="button-active mleft20 button-icon"
            disabled={loading}
            onClick={handleSearchCustomFilters}
          >
            Search {loading ? <LoadingOutlined /> : <SearchOutlined />}
          </Button>
        </div>
      </div>

      <InsightSummaryWidgets
        placementType={activeClass}
        loading={loading}
        partnersOffers={partnersOffers}
        campaignsDailySales={campaignsDailySales}
        campaignsInsights={campaignsInsights}
        companyId={company?.company_Id}
        filters={filters}
      ></InsightSummaryWidgets>
    </>
  );
};

export default InsightNew;
