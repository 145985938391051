import { LoadingOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import {
  InsightsCampaignPartnersData,
  InsightsHostPartners,
} from "../../../../types/events.interfaces";
import { PlacementStage } from "../../../../types/templates.interfaces";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";
import OfferAdvancedInsights from "../offerAdvancedInsights/OfferAdvancedInsights";
import { useState } from "react";
import useAuth from "../../../../hooks/useAuth.hook";
import CommissionTooltip from "../Tooltips/CommissionTooltip";
import SalesTooltip from "../Tooltips/SalesTooltip";
interface Props {
  loading: boolean;
  partnersData: InsightsHostPartners;
  placementStage: PlacementStage | "all";
  campaignId?: string;
  companyId?: string;
  filters: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
}

const PartnersOffers = ({
  loading,
  partnersData,
  placementStage,
  campaignId,
  companyId,
  filters,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState<string>("");
  const [salesNumber, setSalesNumber] = useState<number>(0);
  const [CTR, setCTR] = useState<number>(0);
  const [CR, setCR] = useState<number>(0);
  const { auth } = useAuth();

  const currency =
    partnersData.data.length > 0
      ? partnersData.data[0].brand.currencySymbol
      : "";

  const clickSum = (): number => {
    let click = 0;
    partnersData.data?.forEach((value) => {
      click += value.totalDistinctClicks;
    });
    return click;
  };

  const impressionsSum = (): number => {
    let impressions = 0;
    partnersData.data?.forEach((value) => {
      impressions += value.distinctViews;
    });
    return impressions;
  };

  const salesSum = (): number => {
    let sales = 0;
    partnersData.data?.forEach((value) => {
      if (placementStage === PlacementStage.pre) {
        sales += value.totalAutoEmailSalesCount;
      } else {
        sales += value.totalSalesCount;
      }
    });
    return sales;
  };

  const ctrAvg = (): number => {
    let ctr = 0;
    partnersData.data?.forEach((value) => {
      ctr += checkForZero(value.totalDistinctClicks, value.distinctViews)
        ? (value.totalDistinctClicks / value.distinctViews) * 100
        : 0;
    });
    return ctr > 0 ? ctr / partnersData.data.length : ctr;
  };

  const crAvg = (): number => {
    let cr = 0;
    partnersData.data?.forEach((value) => {
      cr += checkForZero(value.totalSalesCount, value.totalDistinctClicks)
        ? (value.totalSalesCount / value.totalDistinctClicks) * 100
        : 0;
    });
    return cr > 0 ? cr / partnersData.data.length : cr;
  };

  const commisionSum = (): number => {
    let commision = 0;
    partnersData.data?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision;
  };

  const commisionAvg = (): number => {
    let commision = 0;
    partnersData.data?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision > 0 ? commision / partnersData.data.length : commision;
  };

  const epcAvg = (): number => {
    let epc = 0;
    partnersData.data?.forEach((value) => {
      epc += checkForZero(value.totalDistinctClicks, value.totalCommission)
        ? value.totalCommission / value.totalDistinctClicks
        : 0;
    });
    return epc > 0 ? epc / partnersData.data.length : epc;
  };

  const ecpmAvg = (): number => {
    let ecpm = 0;
    partnersData.data?.forEach((value) => {
      ecpm += checkForZero(value.totalCommission, value.distinctViews)
        ? (value.totalCommission / value.distinctViews) * 1000
        : 0;
    });
    return ecpm > 0 ? ecpm / partnersData.data.length : ecpm;
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  interface DataType {
    key: React.Key;
    brand: string;
    offer: string;
    impressions: number;
    clicks: number;
    shareOfClicks: string;
    ctr: string;
    sales: number;
    cr: string;
    cpa: string;
    commision: string;
    epc: string;
    ecpm: string;
    transactionsWebPendingCount: number;
    transactionsWebApprovedCount: number;
    transactionsAutoEmailPendingCount: number;
    transactionsAutoEmailApprovedCount: number;
    transactionsManualEmailPendingCount: number;
    transactionsManualEmailApprovedCount: number;
    transactionsSharePendingCount: number;
    transactionsShareApprovedCount: number;
    totalWebPendingAmount: number;
    totalWebApprovedAmount: number;
    totalAutoEmailApprovedAmount: number;
    totalAutoEmailPendingAmount: number;
    totalManualEmailPendingAmount: number;
    totalManualEmailApprovedAmount: number;
    totalSharePendingAmount: number;
    totalShareApprovedAmount: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Brand",
      dataIndex: "brand",
      fixed: "left",
      width: 120,
    },
    {
      title: "Offer",
      dataIndex: "offer",
      fixed: "left",
      width: 150,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      sorter: (a: DataType, b: DataType) => a.impressions - b.impressions,
      align: "center",
      width: 150,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      sorter: (a: DataType, b: DataType) => a.clicks - b.clicks,
      align: "center",
      width: 120,
    },
    {
      title: "Share of clicks",
      dataIndex: "shareOfClicks",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.shareOfClicks) -
        stripAndReturnNumber(b.shareOfClicks),
      align: "center",
      width: 180,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ctr) - stripAndReturnNumber(b.ctr),
      align: "center",
      width: 100,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      sorter: (a: DataType, b: DataType) => a.sales - b.sales,
      align: "center",
      width: 120,
      render: (sales: number, record: DataType) => (
        <span>
          {sales}
          {sales > 0 && <SalesTooltip record={record} />}
        </span>
      ),
    },
    {
      title: "CR",
      dataIndex: "cr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.cr) - stripAndReturnNumber(b.cr),
      align: "center",
      width: 100,
    },
    {
      title: "CPA",
      dataIndex: "cpa",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.cpa) - stripAndReturnNumber(b.cpa),
      align: "center",
      width: 100,
    },
    {
      title: "Commission",
      dataIndex: "commision",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.commision) - stripAndReturnNumber(b.commision),
      align: "center",
      width: 150,
      render: (commision: string, record: DataType) => (
        <span>
          {commision}
          <span>
            {commision !== "£0.00" && <CommissionTooltip record={record} />}
          </span>
        </span>
      ),
    },
    {
      title: "EPC",
      dataIndex: "epc",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epc) - stripAndReturnNumber(b.epc),
      align: "center",
      width: 100,
    },
    {
      title: "ECPM",
      dataIndex: "ecpm",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ecpm) - stripAndReturnNumber(b.ecpm),
      align: "center",
      width: 100,
    },
  ];

  const data = partnersData?.data?.map(
    (data: InsightsCampaignPartnersData, index: number) => {
      return {
        key: index.toString(),
        brand: data.brand.brand,
        brandoid: data.brand.oid,
        offer: data.brand.oname,
        impressions: data.distinctViews,
        clicks: data.totalDistinctClicks,
        shareOfClicks: `${
          checkForZero(data.totalDistinctClicks)
            ? ((data.totalDistinctClicks / clickSum()) * 100).toFixed(2)
            : "0.00"
        }%`,
        ctr: `${
          checkForZero(data.distinctViews, data.totalDistinctClicks)
            ? ((data.totalDistinctClicks / data.distinctViews) * 100).toFixed(2)
            : "0.00"
        }%`,
        sales: data.totalSalesCount,
        cr: `${
          checkForZero(data.totalDistinctClicks, data.totalSalesCount)
            ? ((data.totalSalesCount / data.totalDistinctClicks) * 100).toFixed(
                2
              )
            : "0.00"
        }%`,
        cpa: data.cpastr,
        commision: `${currency}${data.totalCommission.toFixed(2)}`,
        epc: `${currency}${
          checkForZero(data.totalDistinctClicks, data.totalCommission)
            ? (data.totalCommission / data.totalDistinctClicks).toFixed(2)
            : "0.00"
        }`,
        epi: `${currency}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? (data.totalCommission / data.distinctViews).toFixed(2)
            : "0.00"
        }`,
        ecpm: `${currency}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? ((data.totalCommission / data.distinctViews) * 1000).toFixed(2)
            : "0.00"
        }`,
        transactionsWebPendingCount: data.transactionsWebPendingCount,
        transactionsWebApprovedCount: data.transactionsWebApprovedCount,
        transactionsAutoEmailPendingCount:
          data.transactionsAutoEmailPendingCount,
        transactionsAutoEmailApprovedCount:
          data.transactionsAutoEmailApprovedCount,
        transactionsManualEmailPendingCount:
          data.transactionsManualEmailPendingCount,
        transactionsManualEmailApprovedCount:
          data.transactionsManualEmailApprovedCount,
        transactionsSharePendingCount: data.transactionsSharePendingCount,
        transactionsShareApprovedCount: data.transactionsShareApprovedCount,
        totalWebPendingAmount: data.totalWebPendingAmount,
        totalWebApprovedAmount: data.totalWebApprovedAmount,
        totalAutoEmailApprovedAmount: data.totalAutoEmailApprovedAmount,
        totalAutoEmailPendingAmount: data.totalAutoEmailPendingAmount,
        totalManualEmailPendingAmount: data.totalManualEmailPendingAmount,
        totalManualEmailApprovedAmount: data.totalManualEmailApprovedAmount,
        totalSharePendingAmount: data.totalSharePendingAmount,
        totalShareApprovedAmount: data.totalShareApprovedAmount,
      };
    }
  );

  const handleRowClick = (record: any) => {
    setSalesNumber(record.sales);
    setCTR(record.ctr);
    setCR(record.cr);
    setSelectedOfferId(record.brandoid);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="widget-description">
        {/* <button
                type="button"
                onClick={() => makeCsv(getTableDataForExport(data, columns), `test.csv`)}
            >
                Download table data CSV
            </button> */}
      </div>
      <div className="widget-table cursor">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
            scroll={{ x: undefined, y: 380 }}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <span className="bold">{impressionsSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <span className="bold">{clickSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <div className="bold">
                      {checkForZero(clickSum(), impressionsSum())
                        ? ((clickSum() / impressionsSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">
                      {ctrAvg().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <span className="bold">{salesSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <div className="bold">
                      {checkForZero(salesSum(), clickSum())
                        ? ((salesSum() / clickSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">{crAvg().toFixed(2)}% avg</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    <div className="bold">
                      {currency}
                      {commisionSum().toFixed(2)}
                    </div>
                    <div className="font-xsmall">
                      {currency}
                      {commisionAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    <div className="bold">
                      {currency}
                      {checkForZero(commisionSum(), clickSum())
                        ? (commisionSum() / clickSum()).toFixed(2)
                        : "0.00"}
                    </div>
                    <div className="font-xsmall">
                      {currency}
                      {epcAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center">
                    <div className="bold">
                      {currency}
                      {checkForZero(commisionSum(), impressionsSum())
                        ? ((commisionSum() / impressionsSum()) * 1000).toFixed(
                            2
                          )
                        : "0.00"}
                    </div>
                    <div className="font-xsmall">
                      {currency}
                      {ecpmAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record), // Click event for row
              };
            }}
          />
        </div>
      </div>
      <div className="widget-footer"></div>
      {open && (
        <OfferAdvancedInsights
          open={open}
          onClose={onClose}
          companyId={companyId}
          campaignId={campaignId}
          offerId={selectedOfferId}
          filters={filters}
          placementType={PlacementStage.post}
          salesNumber={salesNumber}
          CTR={CTR}
          CR={CR}
        ></OfferAdvancedInsights>
      )}
    </>
  );
};

export default PartnersOffers;
