import React, { useEffect, useState } from "react";
import "./PaymentsDetails.scss";
import { Button, Empty, message, Select } from "antd";
import {
  // transactionStatusArray,
  // paymentStatusArray,
  FinancialRecord,
  startYear,
  MonthlyFinancialRecord,
} from "../../../types/payments.interfaces";
import useAxios from "../../../hooks/useAxios.hook";
import { LoadingOutlined } from "@ant-design/icons";
import { stringDateToMoment } from "../../../utility/date";
import { Pagination } from "../../../types/global.interfaces";
import { useParams } from "react-router-dom";

interface PaymentsDetailsProps {}

const PaymentsDetails: React.FC<PaymentsDetailsProps> = (props) => {
  const { myAxios } = useAxios();
  const { companyId } = useParams();
  // const [transactionStatus, setTransactionStatus] = useState<string>("");
  // const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>();
  const [payments, setPayments] = useState<FinancialRecord[]>([]);
  const [monthlyPaymentData, setMonthlyPaymentData] = useState<
    MonthlyFinancialRecord[]
  >([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  useEffect(() => {
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (month) {
      getMonthlyPaymentData(month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const getPayments = async () => {
    setLoading(true);
    setLoadingTable(true);

    const { response } = await myAxios({
      method: "GET",
      url: `/payments/host/monthly/${companyId}/${year}`,
    });

    if (response?.status) {
      setPayments(response.data.result.data);
      if (
        response.data.result.data &&
        response.data.result.data?.[0]?.monthNumber
      ) {
        getMonthlyPaymentData(response.data.result.data?.[0].monthNumber);
        setMonth(response.data.result.data?.[0].monthNumber);
      }
    } else {
      message.error("Payments failed to load", 1);
    }

    setLoading(false);
    setLoadingTable(false);
  };

  const getMonthlyPaymentData = async (month: number) => {
    setLoadingTable(true);

    const { response } = await myAxios({
      method: "GET",
      url: `/payments/host/monthly/transactions/${companyId}/${year}/${month}/${pagination.page}/${pagination.pageSize}`,
    });

    if (response?.status) {
      setMonthlyPaymentData(response.data.result.data);
      setTotalPages(response.data.result.totalPages);
    } else {
      message.error("Payments failed to load", 1);
    }

    setLoadingTable(false);
  };

  const createYearsArray = (startYear: number) => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];

    for (let year = startYear; year <= currentYear; year++) {
      yearsArray.push({ label: year.toString(), value: year });
    }

    return yearsArray;
  };

  const handleMonthClick = (monthNumber: number) => {
    getMonthlyPaymentData(monthNumber);
    setMonth(monthNumber);
    setPagination({
      page: 1,
      pageSize: 10,
    });
  };

  return (
    <div className="flex payments">
      <div className="payments-left">
        {!loading ? (
          payments?.length ? (
            payments.map((payment: FinancialRecord, index: number) => (
              <div
                className={`payments-item ${
                  payment.monthNumber === month ? "active-month" : ""
                }`}
                onClick={() => handleMonthClick(payment.monthNumber)}
                key={index}
              >
                <div className="payments-item-date">
                  {payment.monthName} {payment.year}
                </div>
                <div className="payments-item-price">
                  {payment?.currencySymbol}
                  {payment.amount.toFixed(2)}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No payments"
              />
            </div>
          )
        ) : (
          <div className="payments-loading-state">
            <LoadingOutlined />
          </div>
        )}
      </div>
      <div className="payments-right">
        <div>
          <div className="layout-options">
            <div className="filters">
              <div className="filter-dropdown flex payments-filters">
                <div className="label-input-wrapper">
                  <label htmlFor="page">
                    <span className="label-input-name">Year</span>
                    <Select
                      suffixIcon={
                        <span className="icon icon-arrow-down"></span>
                      }
                      className="label-select"
                      value={year}
                      onChange={(value: number) => {
                        setYear(value);
                      }}
                      options={createYearsArray(startYear)}
                    ></Select>
                  </label>
                </div>
                {/* <div className="label-input-wrapper">
                  <label htmlFor="page">
                    <span className="label-input-name">Transaction status</span>
                    <Select
                      suffixIcon={
                        <span className="icon icon-arrow-down"></span>
                      }
                      className="label-select"
                      value={transactionStatus ? transactionStatus : "All"}
                      onChange={(value: string) => {
                        setTransactionStatus(value);
                      }}
                      options={transactionStatusArray}
                    ></Select>
                  </label>
                </div>
                <div className="label-input-wrapper">
                  <label htmlFor="page">
                    <span className="label-input-name">Payment status</span>
                    <Select
                      suffixIcon={
                        <span className="icon icon-arrow-down"></span>
                      }
                      className="label-select"
                      value={paymentStatus ? paymentStatus : "All"}
                      onChange={(value: string) => {
                        setPaymentStatus(value);
                      }}
                      options={paymentStatusArray}
                    ></Select>
                  </label>
                </div> */}
                <Button className="apply-button" onClick={getPayments}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <div className="table-wrapper table-scrollable-wrapper">
            <div className="table-scrollable">
              <table>
                <thead>
                  <tr>
                    <th>Date / Time</th>
                    <th>Advertiser</th>
                    <th>Offer</th>
                    <th>Amount</th>
                    <th>Network</th>
                  </tr>
                </thead>

                <tbody>
                  <>
                    {monthlyPaymentData?.map(
                      (item: MonthlyFinancialRecord, index: number) => {
                        return (
                          <tr key={`offer-${index}`}>
                            <td className="td-min-100">
                              {stringDateToMoment(item.transactionDate).format(
                                "D MMMM YYYY HH:mm:ss"
                              )}
                            </td>
                            <td className="td-min-100">{item.advertiser}</td>
                            <td className="td-min-100">{item.offer}</td>
                            <td className="td-min-100">
                              {item.currencySymbol}
                              {item.computedCommission}
                            </td>
                            <td className="td-min-100">{item.network}</td>
                          </tr>
                        );
                      }
                    )}

                    {loadingTable ? (
                      <tr
                        className={
                          monthlyPaymentData.length > 0 ? "loader-absolute" : ""
                        }
                      >
                        <td className="text-center" colSpan={7}>
                          <LoadingOutlined />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {monthlyPaymentData.length === 0 && (
                          <tr>
                            <td className="text-center" colSpan={7}>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="There are no payments."
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>

          <div className="pagination">
            <div
              className="pagination-prev"
              onClick={() =>
                pagination.page > 1 &&
                setPagination((prevState) => ({
                  ...prevState,
                  page: prevState.page - 1,
                }))
              }
            >
              <span className="pagination-box"></span>
              <span className="pagination-label">Preview</span>
            </div>

            <ul>
              <>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page: number) => {
                    if (
                      (page < pagination.page + 3 &&
                        page > pagination.page - 3) ||
                      page === 1 ||
                      page === totalPages
                    ) {
                      return (
                        <React.Fragment key={page}>
                          {" "}
                          {page > 2 && page + 2 <= pagination.page && (
                            <span className="icon icon-pagination"></span>
                          )}
                          <li
                            onClick={() =>
                              page !== pagination.page &&
                              setPagination((prevState) => ({
                                ...prevState,
                                page,
                              }))
                            }
                            className={page === pagination.page ? "active" : ""}
                          >
                            <span className="pagination-box">{page}</span>
                          </li>
                          {page + 1 < totalPages &&
                            page - 2 >= pagination.page && (
                              <span className="icon icon-pagination"></span>
                            )}
                        </React.Fragment>
                      );
                    }
                    return <React.Fragment key={`rf${page}`}></React.Fragment>;
                  }
                )}
              </>
            </ul>

            <div
              className="pagination-next"
              onClick={() =>
                pagination.page < totalPages &&
                setPagination((prevState) => ({
                  ...prevState,
                  page: prevState.page + 1,
                }))
              }
            >
              <span className="pagination-label">Next</span>
              <span className="pagination-box"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsDetails;
