import { useMemo, useState } from "react";
import { PlacementStage } from "../../types/templates.interfaces";
import Footer from "../footer/footer.component";
import InsightNew from "./Insight-new.component";
import InsightSummary from "./InsightSummary";
import OfferAdvancedInsightsPage from "./widgets/offerAdvancedInsights/OfferAdvancedInsightsPage";
import useAuth from "../../hooks/useAuth.hook";

const Insight = () => {
  const { auth } = useAuth();
  const [activeClass, setActiveClass] = useState<
    PlacementStage | "all" | "advanced"
  >("all");
  const getCurrentComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    switch (activeClass) {
      case "all":
        jsxElm = <InsightSummary activeClass={activeClass} />;
        break;
      case PlacementStage.pre:
        jsxElm = <InsightNew activeClass={activeClass} />;
        break;
      case PlacementStage.post:
        jsxElm = <InsightNew activeClass={activeClass} />;
        break;
      case "advanced":
        jsxElm = <OfferAdvancedInsightsPage salesNumber={1} />;
        break;
      default:
        break;
    }
    return jsxElm;
  }, [activeClass]);

  return (
    <div className="content">
      <div className="content-header">
        <ul className="content-list">
          <li
            className={activeClass === "all" ? "active" : ""}
            onClick={() => setActiveClass("all")}
          >
            Summary
          </li>
          <li
            className={activeClass === "pre" ? "active" : ""}
            onClick={() => setActiveClass(PlacementStage.pre)}
          >
            Pre placements
          </li>
          <li
            className={activeClass === "post" ? "active" : ""}
            onClick={() => setActiveClass(PlacementStage.post)}
          >
            Post placements
          </li>
          {auth?.isSuperAdmin && (
            <li
              className={activeClass === "advanced" ? "active" : ""}
              onClick={() => setActiveClass("advanced")}
            >
              Advanced insights
            </li>
          )}
        </ul>
      </div>
      <div className="content-layout content-insight">
        {getCurrentComponent}
      </div>
      <Footer />
    </div>
  );
};

export default Insight;
