import "./OfferAdvancedInsights.scss";
import OrdersWeekHeatmap from "./graphs/OrdersWeekHeatmap";
import { PlacementStage } from "../../../../types/templates.interfaces";
import SalesFunnelData from "./graphs/SalesFunnelData";
import PerformanceByDeviceTypeDonut from "./graphs/PerformanceByDeviceTypeDonut";
import TopCategoriesBarRace from "./graphs/TopCategoriesBarRace";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Dropdown, Button, DatePicker, message } from "antd";
import { stringDateToMoment } from "../../../../utility/date";
import { DropDown } from "../../../../types/global.interfaces";
import { useState } from "react";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { RangeValue } from "rc-picker/lib/interface";
import useAuth from "../../../../hooks/useAuth.hook";
interface Props {
  companyId?: string;
  campaignId?: string;
  offerId?: string;
  placementType?: PlacementStage;
  salesNumber: number;
  CTR?: number;
  CR?: number;
}

const { RangePicker } = DatePicker;

const filterByDate: DropDown[] = [
  { label: "Last week", key: "lastweek" },
  { label: "This week", key: "thisweek" },
  { label: "Last month", key: "lastmonth" },
  { label: "This month", key: "thismonth" },
  { label: "Last 90 days", key: "last90days" },
  { label: "Yesterday", key: "yesterday" },
  { label: "Today", key: "today" },
  { label: "Custom", key: "custom" },
];

const OfferAdvancedInsightsPage = ({
  campaignId,
  offerId,
  placementType,
  salesNumber,
}: Props) => {
  const { company } = useAuth();
  const [currentDateFilterType, setDateFilterType] = useState<DropDown>({
    label: "Yesterday",
    key: "yesterday",
  });
  const [filters, setFilters] = useState({
    dateFilterType: "yesterday",
    fromDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTrigger, setSearchTrigger] = useState<number>(0); // Add state for search trigger

  const handleChangeDateFilterType = (label: string) => {
    const newDateFilter = filterByDate.find((value) => value.key === label);
    if (newDateFilter) {
      setDateFilterType(newDateFilter);
      setFilters({
        ...filters,
        dateFilterType: newDateFilter.key,
        fromDate: "",
        endDate: "",
      });
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > moment().add(-1, "day");
  };

  const handleChangeDateStartEnd = (
    value: RangeValue<moment.Moment> | null
  ) => {
    if (value) {
      const fromDate = value[0]?.format("YYYY-MM-DD");
      const endDate = value[1]?.format("YYYY-MM-DD");
      if (fromDate && endDate) {
        setFilters({
          ...filters,
          dateFilterType: "custom",
          fromDate: fromDate,
          endDate: endDate,
        });
        setDateFilterType({ label: "Custom", key: "custom" });
      }
    } else {
      setFilters({
        ...filters,
        dateFilterType: "thisweek",
        fromDate: "",
        endDate: "",
      });
      setDateFilterType({ label: "This week", key: "thisweek" });
    }
  };

  const handleSearchCustomFilters = () => {
    setLoading(true);
    setSearchTrigger((prev) => prev + 1);
    setLoading(false);
  };

  return (
    <div className="insights-drawer">
      <div className="layout-options">
        <div className="layout-actions"></div>
        <div className="filters">
          <Dropdown
            className="min150 mright20"
            menu={{
              items: filterByDate,
              selectable: true,
              defaultSelectedKeys: ["yesterday"],
              onClick: (data) => {
                handleChangeDateFilterType(data.key);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span> {currentDateFilterType?.label}</span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>

          <RangePicker
            value={[
              filters.fromDate ? stringDateToMoment(filters.fromDate) : null,
              filters.endDate ? stringDateToMoment(filters.endDate) : null,
            ]}
            className="filter-date"
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            disabled={currentDateFilterType.key !== "custom"}
            onChange={handleChangeDateStartEnd}
          />

          <Button
            type="primary"
            className="button-active mleft20 button-icon"
            disabled={loading}
            onClick={handleSearchCustomFilters}
          >
            Search {loading ? <LoadingOutlined /> : <SearchOutlined />}
          </Button>
        </div>
      </div>
      <div className="insights-drawer-item">
        <SalesFunnelData
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={filters}
          placementType={placementType}
          salesNumber={salesNumber}
          searchTrigger={searchTrigger}
        />
      </div>
      <div
        className={`insights-drawer-item ${
          !campaignId && !offerId ? "insights-drawer-item--page" : ""
        }`}
      >
        <OrdersWeekHeatmap
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={filters}
          salesNumber={salesNumber}
          searchTrigger={searchTrigger}
        />
      </div>
      <div
        className={`insights-drawer-item flex-space-between ${
          !campaignId && !offerId ? "insights-drawer-item--page" : ""
        }`}
      >
        <PerformanceByDeviceTypeDonut
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={filters}
          salesNumber={salesNumber}
          searchTrigger={searchTrigger}
        />
        <TopCategoriesBarRace
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={filters}
          salesNumber={salesNumber}
          searchTrigger={searchTrigger}
        />
      </div>
    </div>
  );
};

export default OfferAdvancedInsightsPage;
