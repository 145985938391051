import { Button, DatePicker, Dropdown, Empty, message } from "antd";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../hooks/useAuth.hook";
import useAxios from "../../hooks/useAxios.hook";
import usePage from "../../hooks/usePage.hook";
import { DropDown } from "../../types/global.interfaces";
import "./css/insight.scss";
import { stringDateToMoment } from "../../utility/date";
import {
  InsightCampaign,
  InsightCampaignPlacement,
  InsightsCampaignDaily,
  InsightsHostPartners,
} from "../../types/events.interfaces";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import InsightWidgets from "./Insight-widgets.component";
import { RangePickerProps } from "antd/lib/date-picker";
import { PlacementStage } from "../../types/templates.interfaces";

const { RangePicker } = DatePicker;

const InsightNew = ({
  activeClass,
}: {
  activeClass: PlacementStage | "all";
}) => {
  const { setPage } = usePage();
  const { auth } = useAuth();
  const { company } = useAuth();
  const [insightCampaings, setInsightCampaigns] = useState<InsightCampaign[]>();
  const [selectedCampaign, setSelectedCampaign] = useState<InsightCampaign>();
  const [selectedPlacements, setSelectedPlacements] = useState<
    InsightCampaignPlacement[]
  >([]);
  const [partnersOffers, setPartnersOffers] = useState<InsightsHostPartners>();
  const [campaignDaily, setCampaignDaily] = useState<InsightsCampaignDaily>();
  const [loading, setLoading] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(true);
  // const [pagination] = useState<Pagination>({
  //   page: 1,
  //   pageSize: 20
  // });

  const [currentDateFilterType, setDateFilterType] = useState<DropDown>({
    label: "Yesterday",
    key: "yesterday",
  });

  const [filters, setFilters] = useState({
    dateFilterType: "yesterday",
    fromDate: "",
    endDate: "",
    placementIds: [""],
  });

  const { myAxios } = useAxios();

  const fetchPartnersOffers = async (companyId: string, campaignId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `insights/host/partners/${companyId}/${campaignId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setPartnersOffers(response.data.result);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchCampaignDaily = async (companyId: string, campaignId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/host/campaignsdaily/${companyId}/${campaignId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setCampaignDaily(response.data.result.data);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchCampaigns = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "get",
      url:
        activeClass === "all"
          ? `campaign/forinsights/${companyId}`
          : `campaign/forinsights/${companyId}/${activeClass}`,
    });

    if (response?.data?.status) {
      setInsightCampaigns(response.data.result.data);
    } else {
      message.error("Failed to get Campaings", 1);
    }
  };

  useEffect(() => {
    setPage({ title: <>Insight</> });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (company && auth) fetchCampaigns(company.company_Id);
    setStart(true);
    // eslint-disable-next-line
  }, [company, auth, activeClass]);

  useMemo(() => {
    if (selectedCampaign && company && start) {
      fetchPartnersOffers(company.company_Id, selectedCampaign.id);
      fetchCampaignDaily(company.company_Id, selectedCampaign.id);
      setStart(false);
    }
    // eslint-disable-next-line
  }, [selectedCampaign]);

  const filterByDate: DropDown[] = [
    { label: "Last week", key: "lastweek" },
    { label: "This week", key: "thisweek" },
    { label: "Last month", key: "lastmonth" },
    { label: "This month", key: "thismonth" },
    { label: "Last 90 days", key: "last90days" },
    { label: "Yesterday", key: "yesterday" },
    { label: "Today", key: "today" },
    { label: "Custom", key: "custom" },
  ];

  const filterByCampaigns: DropDown[] = useMemo(() => {
    setLoading(true);
    let _insightCampaigns: DropDown[] = [
      { label: "no data", key: "no-data", disabled: true },
    ];
    if (insightCampaings && insightCampaings?.length > 0) {
      const _campaign = selectedCampaign
        ? insightCampaings.find((f) => f.id === selectedCampaign.id)
        : insightCampaings[0];
      if (_campaign) {
        const _placementIds = _campaign.placementSettings.map((p) => p._id);
        setSelectedCampaign(_campaign);
        setSelectedPlacements(_campaign.placementSettings);
        setFilters((prevState) => ({
          ...prevState,
          placementIds: _placementIds,
        }));
      }
      _insightCampaigns = insightCampaings?.map((campaign) => ({
        label: campaign.name,
        key: campaign.id,
      }));
    }

    setLoading(false);
    return _insightCampaigns;
    // eslint-disable-next-line
  }, [insightCampaings]);

  const handleChangeDateFilterType = (label: string) => {
    const newDateFilter = filterByDate.find((value) => value.key === label);
    const isCustom = label === "custom";
    isCustom &&
      setPartnersOffers(
        (prevState) => prevState && { ...prevState, fromDate: "", toDate: "" }
      );
    if (newDateFilter) {
      setDateFilterType(newDateFilter);
      setFilters({
        ...filters,
        dateFilterType: newDateFilter.key,
        fromDate: "",
        endDate: "",
      });
    }
  };

  const handleChangeCampaign = (
    key: string,
    _insightCampaigns: InsightCampaign[] | undefined
  ) => {
    const _campaign = _insightCampaigns?.find((f) => f.id === key);
    if (_campaign) {
      const _placementIds = _campaign.placementSettings.map((p) => p._id);
      setSelectedPlacements(_campaign.placementSettings);
      setFilters((prevState) => ({
        ...prevState,
        placementIds: _placementIds,
      }));
      setSelectedCampaign(_campaign);
    } else {
      setFilters((prevState) => ({ ...prevState, placementIds: [] }));
      setSelectedPlacements([]);
      setSelectedCampaign(undefined);
    }
  };

  // const disabledStartDate: RangePickerProps['disabledDate'] = (current: moment.Moment) => {
  //   // to recheck
  //   // const _endDate = endDate ? stringDateToMoment(endDate).add(-1, 'day') : stringDateToMoment(endDate);
  //   // Can not select days before today and after end date
  //   return current && (current < moment().add(-1, 'day') || current > stringDateToMoment(filters.endDate));
  // };

  // const disabledEndDate: RangePickerProps['disabledDate'] = (current: moment.Moment) => {
  //   // To recheck
  //   // const _startDate = startDate ? stringDateToMoment(startDate).add(-1, 'day') : stringDateToMoment(startDate);
  //   // Can not select date before today and before start date
  //   return current && (current < stringDateToMoment(filters.fromDate) || current < moment().add(-1, 'day'));
  // };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > moment().add(-1, "day");
  };

  const handleChangePlacement = (
    checked: boolean,
    _insightPlacements: InsightCampaignPlacement
  ) => {
    if (checked) {
      setFilters({
        ...filters,
        placementIds: [...filters.placementIds, _insightPlacements._id],
      });
      setSelectedPlacements((prevState) => [...prevState, _insightPlacements]);
    } else {
      handleRemovePlacement(_insightPlacements._id);
    }
  };

  const handleChangeDateStartEnd = (
    value: RangeValue<moment.Moment> | null
  ) => {
    if (value) {
      const fromDate = value[0]?.format("YYYY-MM-DD");
      const endDate = value[1]?.format("YYYY-MM-DD");
      if (fromDate && endDate) {
        setFilters({
          ...filters,
          dateFilterType: "custom",
          fromDate: fromDate,
          endDate: endDate,
        });
        setDateFilterType({ label: "Custom", key: "custom" });
      }
    } else {
      setFilters({
        ...filters,
        dateFilterType: "thisweek",
        fromDate: "",
        endDate: "",
      });
      setDateFilterType({ label: "This week", key: "thisweek" });
    }
  };

  const handleSearchCustomFilters = () => {
    if (selectedCampaign && company) {
      fetchPartnersOffers(company.company_Id, selectedCampaign.id);
      fetchCampaignDaily(company.company_Id, selectedCampaign.id);
    }
  };

  const getPlacementsName = (
    placement?: InsightCampaignPlacement[]
  ): string => {
    const pName: string[] = placement
      ? placement.map((value: InsightCampaignPlacement) => value.name)
      : [];
    return pName.join(", ");
  };

  const handleCheckedPlacement = (id: string): boolean => {
    let isChecked = selectedPlacements.map((p) => p._id).includes(id);
    return isChecked;
  };

  const handleRemovePlacement = (id: string) => {
    const _placements = selectedPlacements.filter((f) => f._id !== id);
    setSelectedPlacements(_placements);
    setFilters((prevState) => ({
      ...prevState,
      placementIds: _placements.map((p) => p._id),
    }));
  };

  return (
    <>
      <div className="layout-options">
        <div className="layout-actions"></div>
        <div className="filters">
          <Dropdown
            className="min150 mright20"
            menu={{
              items: filterByDate,
              selectable: true,
              defaultSelectedKeys: ["yesterday"],
              onClick: (data) => {
                handleChangeDateFilterType(data.key);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span> {currentDateFilterType?.label}</span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>

          <RangePicker
            value={[
              filters.fromDate
                ? stringDateToMoment(filters.fromDate)
                : partnersOffers?.fromDate
                ? stringDateToMoment(partnersOffers?.fromDate)
                : null,
              filters.endDate
                ? stringDateToMoment(filters.endDate)
                : partnersOffers?.toDate
                ? stringDateToMoment(partnersOffers?.toDate)
                : null,
            ]}
            className="filter-date"
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            disabled={currentDateFilterType.key !== "custom"}
            onChange={handleChangeDateStartEnd}
          />

          <Button
            type="primary"
            className="button-active mleft20 button-icon"
            disabled={loading}
            onClick={handleSearchCustomFilters}
          >
            {" "}
            Search {loading ? <LoadingOutlined /> : <SearchOutlined />}{" "}
          </Button>
        </div>
      </div>

      <div className="layout-options">
        <div className="layout-actions">
          <Dropdown
            className="min150 mright20"
            menu={{
              items: filterByCampaigns,
              selectable: true,
              selectedKeys: [selectedCampaign?.id || "No data"],
              onClick: (data) => {
                handleChangeCampaign(data.key, insightCampaings);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span className="max-1line">
                Campaign: {selectedCampaign?.name || "No data"}
              </span>{" "}
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>
        </div>

        <div className="filters">
          <Dropdown
            className="dropdown-medium"
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  {selectedPlacements && selectedPlacements.length > 0 && (
                    <>
                      <div className="dropdown-title">Selected placements:</div>
                      <ul className="list-tags">
                        {selectedPlacements?.map(
                          (placement: InsightCampaignPlacement) => {
                            return (
                              <li key={`selected-placements-${placement._id}`}>
                                {placement.name}
                                <span
                                  className="icon icon-remove mleft10 font-hover"
                                  onClick={() =>
                                    handleRemovePlacement(placement._id)
                                  }
                                ></span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Placements found:</div>
                  <ul>
                    {selectedCampaign?.placementSettings?.map(
                      (placement: InsightCampaignPlacement) => {
                        return (
                          <li key={`details-plc-${placement._id}`}>
                            <Checkbox
                              checked={handleCheckedPlacement(placement._id)}
                              onChange={(e: CheckboxChangeEvent) =>
                                handleChangePlacement(
                                  e.target.checked,
                                  placement
                                )
                              }
                              className="checkbox-active"
                            >
                              {placement.name}
                            </Checkbox>
                          </li>
                        );
                      }
                    )}
                    {selectedCampaign &&
                      selectedCampaign?.placementSettings.length === 0 && (
                        <li>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No data"
                          />
                        </li>
                      )}
                  </ul>
                </div>
              );
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span className="max-1line">
                {" "}
                Placements: {getPlacementsName(selectedPlacements) ||
                  "No data"}{" "}
              </span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>
        </div>
      </div>

      <InsightWidgets
        placementType={activeClass}
        loading={loading}
        partnersOffers={partnersOffers}
        campaignDaily={campaignDaily}
        campaignId={selectedCampaign?.id}
        companyId={company?.company_Id}
        filters={filters}
      ></InsightWidgets>
    </>
  );
};

export default InsightNew;
