import { Navigate, Route, Routes } from "react-router-dom";
import Campaigns from "../components/campaigns/Campaigns.component";
import Dashboard from "../components/dashboard/Dashboard.component";
import ForgotPassword from "../components/forgot-password/ForgotPassword.component";
import Offers from "../components/offers/Offers.component";
import Insight from "../components/insight/Insight.component";
import Login from "../components/login/Login.component";
import NotFound from "../components/not-found/NotFound.component";
import Users from "../components/users/Users.component";
import UnauthLayout from "../components/layouts/unauth-layout/UnauthLayout.layout";
import Onboarding from "../components/onboarding/Onboarding.component";
import AuthGuard from "./AuthGuard.component";
import UnAuthGuard from "./UnAuthGuard.component";
import AuthLayout from "../components/layouts/auth-layout/AuthLayout.layout";
import ChangePassword from "../components/change-password/ChangePassword.component";
import Campaign from "../components/campaigns/single/Campaign.component";
import useAuth from "../hooks/useAuth.hook";
import { ACCESS_TYPES, COMPANY_TYPES } from "../utility/enums/user.enums";
import { ExpressOnboardingProvider } from "../components/express/context/expressOnboardingProvider.context";
import ExpressAuth from "../components/express/layouts/ExpressAuth.component";
import ExpressUnAuth from "../components/express/layouts/ExpressUnAuth.component";
import ExpressDashboard from "../components/express/pages/ExpressDashboard.component";
import ExpressInsights from "../components/express/pages/ExpressInsights.component";
import ExpressSettings from "../components/express/pages/ExpressSettings.component";
import ExpressOffers from "../components/express/pages/ExpressOffers.component";
import ExpressCampaign from "../components/express/pages/ExpressCampaign.component";
import ExpressStripeReturn from "../components/express/pages/ExpressStripeReturn.component";
import CodeAuthentication from "../components/authentication/email/codeAuthentication.component";
import InsightNew from "../components/insightv2/Insight";
import OffersHost from "../components/offers-host/OffersHost.component";
import CreateHostOffer from "../components/offers-host/create/CreateOffer.component";
import Customizations from "../components/customizations/Customizations";
import Payments from "../components/payments/Payments";
import CreateOffer from "../components/offers/create/CreateOffer.component";
import Settings from "../components/settings/Settings.component";

const AppRoutes = () => {
  const { company } = useAuth();
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route element={<UnAuthGuard />}>
          <Route element={<UnauthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Onboarding />} />
            <Route
              path="/authentication/:token"
              element={<CodeAuthentication />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/user/changepassword/:paramToken"
              element={<ChangePassword />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>

        {/* Express activation routes */}
        <Route
          element={
            <ExpressOnboardingProvider>
              <ExpressUnAuth />
            </ExpressOnboardingProvider>
          }
          path="/express/:id"
        ></Route>
        <Route
          element={
            <ExpressOnboardingProvider>
              <ExpressAuth />
            </ExpressOnboardingProvider>
          }
        >
          <Route element={<ExpressDashboard />} path="/express/dashboard" />
          <Route element={<ExpressInsights />} path="/express/insights" />
          <Route element={<ExpressSettings />} path="/express/settings" />
          <Route element={<ExpressOffers />} path="/express/deals" />
          <Route element={<ExpressCampaign />} path="/express/campaign" />
          <Route
            element={<ExpressStripeReturn />}
            path="/express/stripereturn"
          />
        </Route>
        {/* Protected routes */}
        <Route element={<AuthGuard />}>
          {/* <Route path="/templates" element={<AdvertisersWrapper />} /> */}
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {company?.userAccess?.access.includes(
              ACCESS_TYPES.insightsread
            ) && (
              <>
                <Route path="/insight-v2" element={<Insight />} />
                <Route path="/insight" element={<InsightNew />} />
              </>
            )}
            {company?.companyCurrentType === COMPANY_TYPES.publisher && (
              <>
                {company?.userAccess?.access.includes(
                  ACCESS_TYPES.campaignread
                ) && (
                  <>
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route path="/campaigns/single" element={<Campaign />} />
                    <Route
                      path="/campaigns/single/:id"
                      element={<Campaign />}
                    />
                  </>
                )}
              </>
            )}

            {company?.companyCurrentType === COMPANY_TYPES.advertiser && (
              <>
                {company?.userAccess?.access.includes(
                  ACCESS_TYPES.offerread
                ) && (
                  <>
                    <Route path="/offers" element={<Offers />} />
                    {company?.userAccess?.access.includes(
                      ACCESS_TYPES.offerwrite
                    ) && (
                      <>
                        <Route
                          path="/offers/create"
                          element={<CreateOffer />}
                        />
                        <Route
                          path="/offers/edit/:id"
                          element={<CreateOffer />}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {company?.companyCurrentType === COMPANY_TYPES.publisher && (
              <>
                {company?.userAccess?.access.includes(
                  ACCESS_TYPES.offerread
                ) && (
                  <>
                    <Route path="/offers-host" element={<OffersHost />} />
                    {company?.userAccess?.access.includes(
                      ACCESS_TYPES.offerwrite
                    ) && (
                      <>
                        <Route
                          path="/offers-host/create"
                          element={<CreateHostOffer />}
                        />
                        <Route
                          path="/offers-host/edit/:id"
                          element={<CreateHostOffer />}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Route path="/users" element={<Users />} />

            {(company?.userAccess?.access.includes(ACCESS_TYPES.brandread) ||
              company?.userAccess?.access.includes(
                ACCESS_TYPES.brandwrite
              )) && (
              <Route
                path="/customisation/edit/:companyId"
                element={<Customizations />}
              />
            )}

            {company?.userAccess?.access.includes(
              ACCESS_TYPES.companywrite
            ) && <Route path="/company/create" element={<Settings />} />}
            {(company?.userAccess?.access.includes(ACCESS_TYPES.companyread) ||
              company?.userAccess?.access.includes(
                ACCESS_TYPES.companywrite
              )) && (
              <Route path="/company/edit/:companyId" element={<Settings />} />
            )}

            {company?.userAccess?.access.includes(
              ACCESS_TYPES.paymentsread
            ) && (
              <Route path="/payments/edit/:companyId" element={<Payments />} />
            )}
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
