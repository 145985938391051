import { useEffect, useMemo, useRef, useState } from "react";
import "./templateFilters.scss";
import { Offer } from "../../../../../../../../types/offer.interfaces";
import {
  CampaignType,
  TemplateOptionSettings,
} from "../../../../../../../../types/campaign.interfaces";

export interface Props {
  settings: TemplateOptionSettings | undefined;
  campaign: CampaignType;
  setFilteredList: React.Dispatch<React.SetStateAction<Offer[]>>;
}

const TemplateFilters = ({ campaign, settings, setFilteredList }: Props) => {
  const [offerHostType, setOfferHostType] = useState<string>("");
  const [offerFullList, setOfferFullList] = useState<Offer[]>(campaign.offers);
  const [offerFilteredList, setOfferFilteredList] = useState<Offer[]>(
    campaign.offers
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const offersCategories = useMemo(() => {
    const cats: string[] = [];

    offerFullList.forEach((o) => {
      o.categories.forEach((cat) => {
        !cats.includes(cat.name) && cats.push(cat.name);
      });
    });

    return cats;
    // eslint-disable-next-line
  }, [offerFullList]);

  useEffect(() => {
    setFilteredList(offerFilteredList);
  }, [offerFilteredList]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    const _offerFullList: Offer[] = [];
    campaign.offers.forEach((o) => {
      if (offerHostType) {
        setSelectedCategory("");
        if (
          (!o.offerSettings.isB2C && offerHostType === "trade") ||
          (o.offerSettings.isB2C && offerHostType === "personal") ||
          offerHostType === "all"
        ) {
          _offerFullList.push(o);
          _offerFilteredList.push(o);
        }
        setOfferFilteredList(_offerFilteredList);
        setOfferFullList(_offerFullList);
      }
    });
    // eslint-disable-next-line
  }, [offerHostType]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    offerFullList.forEach((o) => {
      if (selectedCategory) {
        o.categories.forEach((cat) => {
          cat.name === selectedCategory && _offerFilteredList.push(o);
        });
      } else {
        _offerFilteredList.push(o);
      }
      setOfferFilteredList(_offerFilteredList);
    });
    // eslint-disable-next-line
  }, [selectedCategory]);

  return (
    <>
      {!offerHostType ? (
        <div className="preview-f-closedfilters">
          {!!campaign.offers.find((o) => o.offerSettings.isB2C) && (
            <div
              className="preview-f-button"
              onClick={() => setOfferHostType("personal")}
              style={{
                color: settings?.filters?.activeColor,
                backgroundColor: settings?.filters?.backgroundColor,
                borderColor: settings?.filters?.borderColor,
              }}
            >
              Show more personal offers
            </div>
          )}
          {!!campaign.offers.find((o) => !o.offerSettings.isB2C) && (
            <div
              className="preview-f-button"
              onClick={() => setOfferHostType("trade")}
              style={{
                color: settings?.filters?.activeColor,
                backgroundColor: settings?.filters?.backgroundColor,
                borderColor: settings?.filters?.borderColor,
              }}
            >
              Show more trade offers
            </div>
          )}
        </div>
      ) : (
        <div className="preview-f-openfilters">
          {!!campaign.offers.find((o) => !o.offerSettings.isB2C) &&
            !!campaign.offers.find((o) => o.offerSettings.isB2C) && (
              <div className="preview-f-filter">
                <ul
                  style={{
                    borderColor: settings?.filters?.borderColor || "#939393",
                  }}
                >
                  <li
                    onClick={() => setOfferHostType("personal")}
                    style={{
                      color:
                        offerHostType === "personal"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        offerHostType === "personal"
                          ? settings?.filters?.backgroundColor || "#e5eef5"
                          : "transparent",
                      borderColor:
                        offerHostType === "personal"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : "transparent",
                    }}
                  >
                    Personal offers
                  </li>
                  <li
                    onClick={() => setOfferHostType("trade")}
                    style={{
                      color:
                        offerHostType === "trade"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        offerHostType === "trade"
                          ? settings?.filters?.backgroundColor || "#e5eef5"
                          : "transparent",
                      borderColor:
                        offerHostType === "trade"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : "transparent",
                    }}
                  >
                    Trade offers
                  </li>
                  <li
                    onClick={() => setOfferHostType("all")}
                    style={{
                      color:
                        offerHostType === "all"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        offerHostType === "all"
                          ? settings?.filters?.backgroundColor || "#e5eef5"
                          : "transparent",
                      borderColor:
                        offerHostType === "all"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : "transparent",
                    }}
                  >
                    All
                  </li>
                </ul>
              </div>
            )}
          <div className="preview-f-filter">
            <ul
              style={{
                borderColor: settings?.filters?.borderColor || "#939393",
              }}
            >
              {offersCategories.map((category) => {
                return (
                  <li
                    onClick={() =>
                      setSelectedCategory(
                        category === selectedCategory ? "" : category
                      )
                    }
                    style={{
                      color:
                        category === selectedCategory
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        category === selectedCategory
                          ? settings?.filters?.backgroundColor || "#e5eef5"
                          : "transparent",
                      borderColor:
                        category === selectedCategory
                          ? settings?.filters?.activeColor || "#0053a0"
                          : "transparent",
                    }}
                  >
                    {category}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateFilters;
