import { Empty, message, Select } from "antd";
import { useState, useEffect } from "react";
import useAxios from "../../../../../hooks/useAxios.hook";
import { DataByDataLayerKey } from "../../../../../types/graphs.interfaces";
import { CompanyDataLayerKey } from "../../../../../types/company.interfaces";
import { LoadingOutlined } from "@ant-design/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface Props {
  companyId?: string;
  campaignId?: string;
  offerId?: string;
  filters?: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
  salesNumber: number;
  searchTrigger?: number;
}

const TopCategoriesBarRace = ({
  companyId,
  campaignId,
  offerId,
  filters,
  salesNumber,
  searchTrigger,
}: Props) => {
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataByDataLayerKey, setDataByDataLayerKey] =
    useState<DataByDataLayerKey[]>();
  const [dataLayersKeys, setDataLayersKeys] = useState<CompanyDataLayerKey[]>();
  const [selectedKey, setSelectedKey] = useState<string>("category");
  const itemsPerPage = 10; // Number of items to show per page
  const [currentPage, setCurrentPage] = useState<number>(0);
  const startIdx = currentPage * itemsPerPage;
  const paginatedData = dataByDataLayerKey?.slice(
    startIdx,
    startIdx + itemsPerPage
  );

  useEffect(() => {
    if (salesNumber) {
      getDataLayerKeys();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!salesNumber) {
      return;
    }

    if (!campaignId) {
      fetchByDataLayerKeysAdvanced();
      return;
    }
    fetchByDataLayerKeys();

    // eslint-disable-next-line
  }, [selectedKey, searchTrigger]);

  const fetchByDataLayerKeys = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/offer/orderkey/${companyId}/${campaignId}/${offerId}`,
      data: { ...filters, key: selectedKey },
    });

    if (response?.data?.status) {
      setDataByDataLayerKey(
        response.data.result.data.sort(
          (a: DataByDataLayerKey, b: DataByDataLayerKey) => b.count - a.count
        )
      );
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchByDataLayerKeysAdvanced = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/transactions/orderkey/${companyId}`,
      data: { ...filters, offerId: offerId || "", key: selectedKey },
    });

    if (response?.data?.status) {
      setDataByDataLayerKey(
        response.data.result.data.sort(
          (a: DataByDataLayerKey, b: DataByDataLayerKey) => b.count - a.count
        )
      );
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const getDataLayerKeys = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "get",
      url: `/company/${companyId}`,
    });

    if (response) {
      setDataLayersKeys(response?.data?.settings?.dataLayer?.keys);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const handleChange = (value: string) => {
    setSelectedKey(value);
  };

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage(
      Math.min(
        currentPage + 1,
        Math.floor((dataByDataLayerKey?.length || 0) / itemsPerPage)
      )
    );
  };

  return (
    <div className="flex-50">
      <div className="flex-align-center chart-header">
        <h1 className="title">Top</h1>
        <Select
          className="device-select mleft10 mright10"
          onChange={handleChange}
          defaultValue={"category"}
        >
          {dataLayersKeys?.map((item: CompanyDataLayerKey) => (
            <Select.Option key={item.key} value={item.key}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        <h1 className="title">of purchased items</h1>
      </div>
      {loading ? (
        <div className="loading-state loading-state--350">
          <LoadingOutlined />
        </div>
      ) : !salesNumber ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : !dataByDataLayerKey?.length ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "bar",
                height: "500",
                spacingBottom: 20,
              },
              title: {
                text: "",
                align: "left",
              },
              subtitle: {
                text: "",
                align: "left",
              },
              xAxis: {
                categories: paginatedData?.map((item) =>
                  item.key ? item.key : "other"
                ),
                title: {
                  text: null,
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                  align: "high",
                },
                labels: {
                  overflow: "justify",
                },
                gridLineWidth: 0,
              },
              tooltip: {
                valueSuffix: "",
              },
              plotOptions: {
                bar: {
                  borderRadius: "1%",
                  dataLabels: {
                    enabled: true,
                  },
                  pointWidth: 20,
                  pointPadding: 0.2,
                },
              },
              legend: {
                enabled: false,
              },
              credits: {
                enabled: false,
              },
              colorAxis: {
                min: 0,
                minColor: "#e6f5f1",
                maxColor: "#3AB78F",
              },
              series: [
                {
                  name: "",
                  data: paginatedData?.map((item) =>
                    item.count ? item.count : 0
                  ),
                },
              ],
            }}
          />
          <div className="pagination">
            <div className="pagination-prev" onClick={handlePrevPage}>
              <span className="pagination-box"></span>
              <span className="pagination-label">Previous</span>
            </div>

            <div className="pagination-next" onClick={handleNextPage}>
              <span className="pagination-label">Next</span>
              <span className="pagination-box"></span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TopCategoriesBarRace;
