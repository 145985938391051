import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import {
  HeatMapDataItem,
  updateHeatMapData,
} from "../../../../../types/graphs.interfaces";
import { Empty, message, Select } from "antd";
import useAxios from "../../../../../hooks/useAxios.hook";
import { DeviceTypeInsight } from "../../../../express/const/campaigns.enum";
import { LoadingOutlined } from "@ant-design/icons";

HighchartsHeatmap(Highcharts);

interface Props {
  companyId?: string;
  campaignId?: string;
  offerId?: string;
  filters?: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
  salesNumber: number;
  searchTrigger?: number;
}

const OrdersWeekHeatmap = ({
  companyId,
  campaignId,
  offerId,
  filters,
  salesNumber,
  searchTrigger
}: Props) => {
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceType, setDeviceType] = useState<DeviceTypeInsight>(
    DeviceTypeInsight.ALL
  );
  const [heatMapData, setHeatMapData] = useState<HeatMapDataItem[]>();
  const [data, setData] = useState<HeatMapDataItem[]>();
  const initHeatMapData: any[] = [
    [0, 0, 0],
    [0, 1, 0],
    [0, 2, 0],
    [0, 3, 0],
    [0, 4, 0],
    [0, 5, 0],
    [0, 6, 0],
    [0, 7, 0],
    [0, 8, 0],
    [0, 9, 0],
    [0, 10, 0],
    [0, 11, 0],
    [0, 12, 0],
    [0, 13, 0],
    [0, 14, 0],
    [0, 15, 0],
    [0, 16, 0],
    [0, 17, 0],
    [0, 18, 0],
    [0, 19, 0],
    [0, 20, 0],
    [0, 21, 0],
    [0, 22, 0],
    [0, 23, 0],

    [1, 0, 0],
    [1, 1, 0],
    [1, 2, 0],
    [1, 3, 0],
    [1, 4, 0],
    [1, 5, 0],
    [1, 6, 0],
    [1, 7, 0],
    [1, 8, 0],
    [1, 9, 0],
    [1, 10, 0],
    [1, 11, 0],
    [1, 12, 0],
    [1, 13, 0],
    [1, 14, 0],
    [1, 15, 0],
    [1, 16, 0],
    [1, 17, 0],
    [1, 18, 0],
    [1, 19, 0],
    [1, 20, 0],
    [1, 21, 0],
    [1, 22, 0],
    [1, 23, 0],

    [2, 0, 0],
    [2, 1, 0],
    [2, 2, 0],
    [2, 3, 0],
    [2, 4, 0],
    [2, 5, 0],
    [2, 6, 0],
    [2, 7, 0],
    [2, 8, 0],
    [2, 9, 0],
    [2, 10, 0],
    [2, 11, 0],
    [2, 12, 0],
    [2, 13, 0],
    [2, 14, 0],
    [2, 15, 0],
    [2, 16, 0],
    [2, 17, 0],
    [2, 18, 0],
    [2, 19, 0],
    [2, 20, 0],
    [2, 21, 0],
    [2, 22, 0],
    [2, 23, 0],

    [3, 0, 0],
    [3, 1, 0],
    [3, 2, 0],
    [3, 3, 0],
    [3, 4, 0],
    [3, 5, 0],
    [3, 6, 0],
    [3, 7, 0],
    [3, 8, 0],
    [3, 9, 0],
    [3, 10, 0],
    [3, 11, 0],
    [3, 12, 0],
    [3, 13, 0],
    [3, 14, 0],
    [3, 15, 0],
    [3, 16, 0],
    [3, 17, 0],
    [3, 18, 0],
    [3, 19, 0],
    [3, 20, 0],
    [3, 21, 0],
    [3, 22, 0],
    [3, 23, 0],

    [4, 0, 0],
    [4, 1, 0],
    [4, 2, 0],
    [4, 3, 0],
    [4, 4, 0],
    [4, 5, 0],
    [4, 6, 0],
    [4, 7, 0],
    [4, 8, 0],
    [4, 9, 0],
    [4, 10, 0],
    [4, 11, 0],
    [4, 12, 0],
    [4, 13, 0],
    [4, 14, 0],
    [4, 15, 0],
    [4, 16, 0],
    [4, 17, 0],
    [4, 18, 0],
    [4, 19, 0],
    [4, 20, 0],
    [4, 21, 0],
    [4, 22, 0],
    [4, 23, 0],

    [5, 0, 0],
    [5, 1, 0],
    [5, 2, 0],
    [5, 3, 0],
    [5, 4, 0],
    [5, 5, 0],
    [5, 6, 0],
    [5, 7, 0],
    [5, 8, 0],
    [5, 9, 0],
    [5, 10, 0],
    [5, 11, 0],
    [5, 12, 0],
    [5, 13, 0],
    [5, 14, 0],
    [5, 15, 0],
    [5, 16, 0],
    [5, 17, 0],
    [5, 18, 0],
    [5, 19, 0],
    [5, 20, 0],
    [5, 21, 0],
    [5, 22, 0],
    [5, 23, 0],

    [6, 0, 0],
    [6, 1, 0],
    [6, 2, 0],
    [6, 3, 0],
    [6, 4, 0],
    [6, 5, 0],
    [6, 6, 0],
    [6, 7, 0],
    [6, 8, 0],
    [6, 9, 0],
    [6, 10, 0],
    [6, 11, 0],
    [6, 12, 0],
    [6, 13, 0],
    [6, 14, 0],
    [6, 15, 0],
    [6, 16, 0],
    [6, 17, 0],
    [6, 18, 0],
    [6, 19, 0],
    [6, 20, 0],
    [6, 21, 0],
    [6, 22, 0],
    [6, 23, 0],
  ];

  useEffect(() => {
    if (!salesNumber) {
      return;
    }
    if (!campaignId) {
      fetchHeatMapDataAdvanced();
      return;
    }
    fetchHeatMapData();
    // eslint-disable-next-line
  }, [deviceType, searchTrigger]);

  const fetchHeatMapData = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/offer/datetime/${companyId}/${campaignId}/${offerId}`,
      data: {
        ...filters,
        deviceType: deviceType,
      },
    });

    if (response?.data?.status) {
      setHeatMapData(
        updateHeatMapData(initHeatMapData, response.data.result.data)
      );
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchHeatMapDataAdvanced = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/transactions/datetime/${companyId}`,
      data: {
        ...filters,
        deviceType: deviceType,
        offerId: offerId || "",
      },
    });

    if (response?.data?.status) {
      setData(response.data.result.data);
      setHeatMapData(
        updateHeatMapData(initHeatMapData, response.data.result.data)
      );
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const handleChange = (value: DeviceTypeInsight) => {
    setDeviceType(value); // Update the state with the new value
  };

  return (
    <>
      <div className="flex-align-center mbot10">
        <h1 className="title">Orders by weekday and time on</h1>
        <Select
          className="device-select mleft10"
          onChange={handleChange}
          defaultValue={deviceType}
        >
          <Select.Option value={DeviceTypeInsight.ALL}>
            {DeviceTypeInsight.ALL} devices
          </Select.Option>
          <Select.Option value={DeviceTypeInsight.DESKTOP}>
            {DeviceTypeInsight.DESKTOP} devices
          </Select.Option>
          <Select.Option value={DeviceTypeInsight.MOBILE}>
            {DeviceTypeInsight.MOBILE} devices
          </Select.Option>
        </Select>
      </div>
      {loading ? (
        <div className="loading-state loading-state--400">
          <LoadingOutlined />
        </div>
      ) : !salesNumber ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : !data?.length ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "heatmap",
              plotBorderWidth: 1,
              height: 600,
            },
            title: {
              text: "",
              style: {
                fontSize: "1em",
              },
            },
            xAxis: {
              categories: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ],
              title: {
                text: null,
              },
              labels: {
                style: {
                  fontSize: "14px",
                },
              },
            },
            yAxis: {
              categories: [
                "00",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
              ],
              title: null,
              reversed: true,
              labels: {
                style: {
                  fontSize: "14px",
                },
              },
            },
            accessibility: {
              point: {
                descriptionFormat:
                  "{(add index 1)}. " +
                  "{series.xAxis.categories.(x)} sales " +
                  "{series.yAxis.categories.(y)}, {value}.",
              },
            },
            colorAxis: {
              min: 0,
              minColor: "#FFFFFF",
              maxColor: "#3AB78F",
            },
            legend: {
              align: "right",
              layout: "vertical",
              margin: 0,
              verticalAlign: "top",
              y: 25,
              symbolHeight: 280,
            },
            tooltip: {
              format:
                "<b>{series.xAxis.categories.(point.x)}</b> sold<br>" +
                "<b>{point.value}</b> items on <br>" +
                "<b>{series.yAxis.categories.(point.y)}</b>",
            },
            series: [
              {
                data: heatMapData,
                dataLabels: {
                  align: "right",
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "14px",
                  },
                },
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    yAxis: {
                      labels: {
                        format: "{substr value 0 1}",
                      },
                    },
                  },
                },
              ],
            },
          }}
        />
      )}
    </>
  );
};

export default OrdersWeekHeatmap;
