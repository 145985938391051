import {
  CampaignType,
  LayoutSettings,
} from "../../../types/campaign.interfaces";
import { DaySlots, Offer } from "../../../types/offer.interfaces";
import {
  DelayType,
  RedeemBy,
  UseFrom,
  VoucherType,
} from "../../../utility/enums/offer.enums";
import {
  ActionSettings,
  BehaviourSettings,
  ButtonLocationSettingsT,
  ButtonSettings,
  ButtonShapes,
  ButtonSizesSettings,
  ButtonStyleSettingsT,
  ElementSettings,
  GeneralSettings,
  ImageSettings,
  ImageSizes,
  InlineSettings,
  LocationPosition,
  LocationSettings,
  PopupSettings,
  GetButtonSettings,
  TextAlign,
  TextSettings,
  TextTransform,
  DisplayBy,
  ButtonNumberSettingsT,
  ImageRepresentationSettings,
  ImageRepresentation,
} from "../../templates-previews/types/params.interfaces";
import { ExpressCampaign } from "../types/express.types";
import { LayoutClass } from "./views";

export enum CAMPAIGNSTEPS {
  details = "details",
  offers = "offers",
  layout = "layout",
}

export enum DATALAYER_TYPE {
  none = "none",
  html = "html",
}

export enum PLACEMENT_IDENTIFIER {
  checkout = "checkout",
  orderconfirmation = "orderconfirmation",
  product = "product",
}

export const CAMPAIGN_STEP_ARRAY: CAMPAIGNSTEPS[] = [
  CAMPAIGNSTEPS.details,
  CAMPAIGNSTEPS.offers,
  CAMPAIGNSTEPS.layout,
];

export const getCampaignStepIndex = (step: CAMPAIGNSTEPS): number => {
  return CAMPAIGN_STEP_ARRAY.findIndex((s) => s === step);
};

const orderExtractorSettings = {
  allowExtraction: false,
  productExtractorKeys: {
    htmlElement: "",
    productsSelector: "",
    productNameSelector: "",
    productPriceSelector: "",
    totalPriceSelector: "",
  },
};

const textSettings: TextSettings = {
  textColor: "#000000",
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "13",
  textTransform: TextTransform.capitalize,
};

const headerSettings: TextSettings = {
  textColor: "#000000",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "13",
  textTransform: TextTransform.capitalize,
};

const headerConfirmationSettings: TextSettings = {
  textColor: "#000000",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16",
  textTransform: TextTransform.capitalize,
};

const buttonStyleSettings: ButtonStyleSettingsT = {
  backgroundColor: "#ffffff",
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  circleSize: 100,
  textColor: "#000000",
  fontFamily: "Poppins",
  fontWeight: 0,
  fontSize: "12",
  textTransform: TextTransform.capitalize,
};

const buttonOrderConfirmStyleSettings: ButtonStyleSettingsT = {
  backgroundColor: "#ffffff",
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  circleSize: 100,
  textColor: "#000000",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "14",
  textTransform: TextTransform.capitalize,
};

const imageRepresentation: ImageRepresentationSettings = {
  position: ImageRepresentation.left,
};

const imageSettings: ImageSettings = {
  size: ImageSizes.xsmall,
  rounding: 5,
};

const generalSettings: GeneralSettings = {
  backgroundColor: "#f2f2f2",
  offers: "3",
  align: TextAlign.left,
};

const buttonNumberSettings: ButtonNumberSettingsT = {
  display: true,
  backgroundColor: "#ff0000",
  textColor: "#ffffff",
};

const getButtonSettings: GetButtonSettings = {
  backgroundColor: "#d1d1d1",
  textColor: "#000000",
  fontFamily: "Poppins",
  fontSize: "12",
  fontWeight: 400,
  rounding: 12,
};

const behaviourSettings: BehaviourSettings = {
  displayBy: DisplayBy.time,
  afterTime: 0,
};

const behaviourConfirmSettings: BehaviourSettings = {
  displayBy: DisplayBy.time,
  afterTime: 3,
};

const locationSettings: LocationSettings = {
  location: LocationPosition.left,
  transition: 0,
};

const locationConfirmDesktopSettings: LocationSettings = {
  location: LocationPosition.center,
  transition: 0,
};

const locationConfirmMobileSettings: LocationSettings = {
  location: LocationPosition.center,
  transition: 0,
};

const buttonLocationSettings: ButtonLocationSettingsT = {
  location: LocationPosition.left,
  x: 0,
  y: 0,
};

const buttonOrderConfirmLocationSettings: ButtonLocationSettingsT = {
  location: LocationPosition.bottomRight,
  x: 0,
  y: 0,
};

const buttonSizesSettings: ButtonSizesSettings = {
  type: "fixed",
  w: 60,
  h: 100,
  circle: 100,
};

const buttonOrderConfirmSizesSettings: ButtonSizesSettings = {
  type: "fixed",
  w: 100,
  h: 150,
  circle: 100,
};

const elementSettings: ElementSettings = {
  attribute: "",
  existingElement: true,
  elementType: "id",
  fullWidth: true,
  width: 300,
  height: 300,
  marginType: "%",
  margin: 0,
  paddingTop: 0,
  paddingBottom: 0,
  extraElementSelector: "",
  extraElementType: "id",
  extraElementBackground: "#CBEFE8",
  extraElementColor: "#009D7E",
  extraElementText: "You have qualified for free gifts!",
  extraElementTextBold: "Don`t forget to make your selection below",
};

export const buttonSettings: ButtonSettings = {
  location: buttonLocationSettings,
  text: "Your rewards",
  sizes: buttonSizesSettings,
  shape: ButtonShapes.squareImageLock,
  style: buttonStyleSettings,
  number: buttonNumberSettings,
};

export const buttonOrderConfirmSettings: ButtonSettings = {
  location: buttonOrderConfirmLocationSettings,
  text: "Your rewards",
  sizes: buttonOrderConfirmSizesSettings,
  shape: ButtonShapes.squareImageLock,
  style: buttonOrderConfirmStyleSettings,
  number: buttonNumberSettings,
};

export const popupSettings: PopupSettings = {
  minimized: true,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationSettings,
  header: headerSettings,
  text: textSettings,
  image: imageSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const actionSettings: ActionSettings = {
  behaviour: behaviourSettings,
  minimized: true,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationSettings,
  header: headerSettings,
  text: textSettings,
  image: imageSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const actionOrderConfirmDesktopSettings: ActionSettings = {
  behaviour: behaviourConfirmSettings,
  minimized: false,
  horizontal: "Horizontal",
  general: generalSettings,
  location: locationConfirmDesktopSettings,
  header: headerConfirmationSettings,
  text: textSettings,
  image: { ...imageSettings, size: ImageSizes.large },
  getButton: getButtonSettings,
  imageRepresentation: { position: ImageRepresentation.top },
};

const actionOrderConfirmMobileSettings: ActionSettings = {
  behaviour: behaviourConfirmSettings,
  minimized: true,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationConfirmMobileSettings,
  header: headerConfirmationSettings,
  text: textSettings,
  image: { ...imageSettings, size: ImageSizes.small },
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const inlineSettings: InlineSettings = {
  minimized: false,
  element: elementSettings,
  general: generalSettings,
  header: textSettings,
  text: textSettings,
  image: imageSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

export const expressSettings: LayoutSettings = {
  desktop: {
    type: LayoutClass.action,
    button: buttonSettings,
    popup: popupSettings,
    inline: inlineSettings,
    action: actionSettings,
    orderExtractor: orderExtractorSettings,
  },
  mobile: {
    type: LayoutClass.action,
    button: buttonSettings,
    popup: popupSettings,
    inline: inlineSettings,
    action: actionSettings,
    orderExtractor: orderExtractorSettings,
  },
};

export const expressOrderConfirmationSettings: LayoutSettings = {
  desktop: {
    type: LayoutClass.action,
    button: buttonOrderConfirmSettings,
    popup: popupSettings,
    inline: inlineSettings,
    action: actionOrderConfirmDesktopSettings,
    orderExtractor: orderExtractorSettings,
  },
  mobile: {
    type: LayoutClass.action,
    button: buttonOrderConfirmSettings,
    popup: popupSettings,
    inline: inlineSettings,
    action: actionOrderConfirmMobileSettings,
    orderExtractor: orderExtractorSettings,
  },
};

export const EXPRESS_CAMPAIGN_INIT: ExpressCampaign = {
  id: "",
  name: "Campaign Example",
  description: "Free gifts with your purchase",
  isActive: false,
  completionStatus: {
    status: false,
    next: CAMPAIGNSTEPS.layout,
  },
  placementSettings: [],
  offers: [],
  autoOffers: true,
};

export const CAMPAIGN_INIT: CampaignType = {
  name: "Campaign Example",
  description: "Free gifts with your purchase",
  isActive: false,
  company_id: "",
  startDate: "",
  endDate: "",
  completionStatus: {
    status: false,
    next: CAMPAIGNSTEPS.layout,
  },
  generalOptions: {
    automaticallyCheckOptIn: false,
    emailCaptureOnAddToOrder: false,
    emailCaptureOnOffers: false,
    saveOfferOptions: {
      allowSavingOffers: false,
      allowEmail: false,
      allowSms: false,
    },
    sendOneEmailPerOffer: false,
    showSelectedOffers: false,
    showAddToOrder: false,
    storeEmailForFuture: false,
    useEmailFromDataLayer: false,
    htmlSelector: "",
    frequencyCapping: {
      isEnabled: false,
      frequency: 0,
      reset: 0,
    },
    dataLayer: {
      htmlLocation: "",
      type: DATALAYER_TYPE.none,
    },
  },
  sharingOptions: {
    allowSharing: false,
    shareAdvertiserOffer: false,
    shareMessage: "",
    sharingPlatforms: {
      platforms: [],
    },
  },
  cookieOptions: {
    allowCookies: false,
    allowSessionCookies: false,
    allowPersistentCookies: true,
  },
  campaignAccessOptions: {
    openToEveryone: true,
    accessParam: "",
  },
  placementSettings: [],
  allowSharing: false,
  offerCount: 1,
  templateId: "",
  offers: [],
};

export const offer: Offer = {
  id: "63d8e0a41c7ace94be1a29bd",
  status: "active",
  displayStatus: "Active",
  type: "promotion",
  title: "Offer title",
  description:
    "<p>Rediscover the simple pleasures of home cooking</p><p>SimplyCook is your new helping hand in the kitchen that makes cooking simply delicious, affordable and flexible.</p><ul> <li>Recipe kits enable you to SimplyCook 140+ chef-created recipes in half an hour or less.</li> <li>Each kit comes with 3 pots of pleasure, packed with up to 23 ingredients, adding extraordinary flavour to your meals.</li><li>Join 100,000+ happy home cooks, who’ve enjoyed 50 million meals (and counting).</li> <li>Try your first box for free (just pay £1 postage) and rediscover midweek cooking with a new ingredient: pleasure.</li></ul>",
  longDescription: "",
  faqs: "",
  terms:
    "<p>Rediscover the simple pleasures of home cooking</p><p>SimplyCook is your new helping hand in the kitchen that makes cooking simply delicious, affordable and flexible.</p><ul> <li>Recipe kits enable you to SimplyCook 140+ chef-created recipes in half an hour or less.</li> <li>Each kit comes with 3 pots of pleasure, packed with up to 23 ingredients, adding extraordinary flavour to your meals.</li><li>Join 100,000+ happy home cooks, who’ve enjoyed 50 million meals (and counting).</li> <li>Try your first box for free (just pay £1 postage) and rediscover midweek cooking with a new ingredient: pleasure.</li></ul>",
  imageUrl:
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80",
  startDate: "2022-12-20T12:18:16.716Z",
  endDate: "2024-12-21T12:18:16.716Z",
  destinationUrl: "",
  trackingUrl:
    "http://localhost:6001/tracking?event=onofferclick&red=https%3a%2f%2fwww.awin1.com%2fcread.php%3fawinmid%3d1111%26awinaffid%3d1174626%26campaign%3dTesting%2bnew%2bupdates%26clickref%3d1174626%26clickref2%3d63d8e0a41c7ace94be1a29bd%26clickref3%3d63d8e05d1c7ace94be1a29bc%26clickref4%3d6464b0c1353b2b9c142890b6%26clickref5%3d%26clickref6%3d%26platform%3dpl%26networkref2%3dcs1_123123123&retailerid=640724d5e22036600655558a&campaignid=6464b0c1353b2b9c142890b6&offerid=63d8e0a41c7ace94be1a29bd&advertiserid=63d8e05d1c7ace94be1a29bc",
  isExternal: true,
  externalNetwork: {
    promotionId: 1234567,
  },
  voucher: {
    code: "",
    voucherType: VoucherType.none,
  },
  advertiser: {
    name: "Another name on offer",
    company_Id: "63d8e05d1c7ace94be1a29bc",
    external_aId: "1111",
    uniqueNetworkIdentifier: "awin",
  },
  offerCpa: {
    display: "0",
    value: 0,
  },
  appearanceRules: {
    frequencyCapping: {
      useFrom: UseFrom.campaign,
      frequency: 0,
      reset: 0,
    },
    relevance: {
      showIfFalse: false,
      slot: "Current",
      rules: [],
    },
    deviceCondition: {
      deviceOS: "both",
      deviceType: "all",
    },
    dateTimeCondition: {
      isEnabled: false,
      daySlots: null,
    },
  },
  media: {
    featured: {
      featuredVideoURL: "",
      images: [],
      title: "",
      description: "",
    },
    mainImage: [],
    videoURL: "",
    resolution: []
  },
  epc: 0,
  offerCTAText: "Offer buton",
  exclusiveTo: {
    categories: [],
    regions: [],
    retailers: [],
  },
  slot: {
    slot: 1,
    percentage: 100,
  },
  offerSettings: {
    allowShare: false,
    redeemBy: RedeemBy.webemail,
    delaySetting: {
      delayType: DelayType.none,
      delayValue: "0",
      emailTemplateId: "",
    },
    isB2C: true,
  },
  categories: [],
  brandswopExclusive: true,
  offerBadge: null,
};

export const emptyDaySlots: DaySlots[] = [
  {
    dayIndex: 1,
    timeSlots: [],
  },
  {
    dayIndex: 2,
    timeSlots: [],
  },
  {
    dayIndex: 3,
    timeSlots: [],
  },
  {
    dayIndex: 4,
    timeSlots: [],
  },
  {
    dayIndex: 5,
    timeSlots: [],
  },
  {
    dayIndex: 6,
    timeSlots: [],
  },
  {
    dayIndex: 7,
    timeSlots: [],
  },
];

export enum DeviceType {
  Desktop = "desktop",
  Mobile = "mobile",
  Both = "both",
}

export enum DeviceTypeInsight {
  DESKTOP = "Desktop",
  MOBILE = "Mobile",
  ALL = "all",
}

export enum DeviceOS {
  All = "all",
  Android = "android",
  IOS = "ios",
}

export const dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const timeSlots = [
  "00-02",
  "02-04",
  "04-06",
  "06-08",
  "08-10",
  "10-12",
  "12-14",
  "14-16",
  "16-18",
  "18-20",
  "20-22",
  "22-00",
];

export const timeSlotsText = [
  "00 - 02",
  "02 - 04",
  "04 - 06",
  "06 - 08",
  "08 - 10",
  "10 - 12",
  "12 - 14",
  "14 - 16",
  "16 - 18",
  "18 - 20",
  "20 - 22",
  "22 - 00",
];

export const timeSlotsBoxes = ["", "", "", "", "", "", "", ""];
