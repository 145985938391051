import {
  DeleteOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Button, Empty, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import useAxios from "../../../../hooks/useAxios.hook";
import { CampaignType } from "../../../../types/campaign.interfaces";
import { Offer } from "../../../../types/offer.interfaces";
import defaultOfferImg from "../../../../assets/images/offer-image.jpg";
import { Pagination } from "../../../../types/global.interfaces";
import useAuth from "../../../../hooks/useAuth.hook";
import { ACCESS_TYPES } from "../../../../utility/enums/user.enums";
import { ApiErrorMessages } from "../../../../types/response.interfaces";
import ModalOfferSlots from "./slots/ModalOfferConditions.component";
import { OFFER_TYPES, RedeemBy } from "../../../../utility/enums/offer.enums";
import ModalOfferSettings from "./settings/offerSettings.component";
import { stringDateToMoment } from "../../../../utility/date";

interface Props {
  campaign: CampaignType;
  setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const CampaignOffers = ({ campaign, setCampaign, setDisabled }: Props) => {
  const { company } = useAuth();
  const [selectedOffers, setSelectedOffers] = useState<Offer[]>(
    campaign.offers
  );
  const [triggers, setTriggers] = useState<string[]>([]);
  const [selectedTriggers, setSelectedTriggers] = useState<string[]>([]);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [offerCondition, setOfferCondition] = useState<Offer>();
  const [isConditionModalOpen, setConditionModalOpen] =
    useState<boolean>(false);
  const [isSlotModalOpen, setSlotModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeClass, setActiveClass] = useState<string>("partner");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 20,
  });
  const [filters, setFilters] = useState({
    advertiserIds: [],
    categories: [],
    brandswopExclusive: "all",
    regionCodes: [],
    type: OFFER_TYPES.advertiser,
    offerSort: {
      ascending: true,
      columnName: "title",
    },
  });

  const { response, axiosFetch } = useAxios();

  const fetchOffers = async (companyId: string) => {
    setLoading(true);
    await axiosFetch({
      method: "post",
      url: `offers/getactiveoffers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`,
      data: filters,
    });
  };

  useEffect(() => {
    if (company) {
      fetchOffers(company.company_Id);
    }
    if (campaign.offers.length === 0) {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [filters, pagination, company]);

  const checkOffersWithRedemtion = (_offer: Offer): boolean => {
    return _offer.offerSettings.redeemBy === RedeemBy.email;
  };

  useEffect(() => {
    setCampaign((prevState) => ({
      ...prevState,
      offers: selectedOffers.sort((a, b) => a.slot.slot - b.slot.slot),
    }));
    setSelectedTriggersFromOffers(selectedOffers);
    setDisabled(selectedOffers.length === 0);
    // eslint-disable-next-line
  }, [selectedOffers]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        const _offers = response.result?.data;
        setTotalPages(response.result?.totalPages);
        setOffers(_offers);
        setTriggersFromOffers(_offers);
        setLoading(false);
      } else {
        response?.result?.length > 0 &&
          response?.result.forEach((value: ApiErrorMessages) => {
            if (value.errorCode !== 12000) {
              message.error(value.message || `Something went wrong!`);
            }
          });
      }
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [response]);

  // Functions to toggle offers description
  const setTriggersFromOffers = (_offer: Offer[]) => {
    _offer.forEach((value: Offer) => {
      !triggers.includes(value.id) &&
        setTriggers((prevState) => [...prevState, value.id]);
    });
  };

  // const removeTriggerById = (id: string) => {
  //     setTriggers(prevState => prevState.filter(f => f !== id));
  // }
  // const removeSelectedTriggerById = (id: string) => {
  //     setSelectedTriggers(prevState => prevState.filter(f => f !== id));
  // }
  // const sliceText = (text: string): string => {
  //     return text.length > 100 ? `${text.slice(0, 100)}` : text
  // }

  const setSelectedTriggersFromOffers = (_offer: Offer[]) => {
    _offer.forEach((value: Offer) => {
      !selectedTriggers.includes(value.id) &&
        setSelectedTriggers((prevState) => [...prevState, value.id]);
    });
  };

  const handleSelect = (_offer: Offer) => {
    if (
      checkOffersWithRedemtion(_offer) &&
      !campaign.generalOptions.emailCaptureOnOffers
    ) {
      message.error(
        "The offer is redeemed by email only. Please enable Email capture on offers in Campaign settings to select it."
      );
    } else {
      const slot = selectedOffers.length + 1;

      const offersWithSameSlot = selectedOffers.filter(
        (offer) => offer.slot.slot === slot
      ).length;
      const percentage =
        offersWithSameSlot === 0
          ? 100
          : Math.floor(100 / (offersWithSameSlot + 1));

      const newOffers = selectedOffers.map((offer) => {
        if (offer.slot.slot === slot) {
          return { ...offer, slot: { slot, percentage } };
        }
        return offer;
      });

      let percentageLeft = 0;

      if (
        offersWithSameSlot !== 0 &&
        percentage * (offersWithSameSlot + 1) !== 100
      ) {
        percentageLeft = 100 - percentage * (offersWithSameSlot + 1);
      }

      const _offerWithSlot = {
        ..._offer,
        slot: { slot, percentage: percentage + percentageLeft },
      };
      setSelectedOffers([...newOffers, _offerWithSlot]);
    }
  };

  const handleRemove = (id: string) => {
    setSelectedOffers((prevState) =>
      prevState.filter((value: Offer) => value.id !== id)
    );
  };

  const openConfirmModal = (id: string) => {
    Modal.confirm({
      title: "Do you want to remove this offer from the selected offers?",
      content: "You can add it again from the available offers",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(id),
    });
  };

  const checkOfferSelected = (id: string): boolean => {
    const promotionIds = selectedOffers.map((offer: Offer) => offer.id);
    return promotionIds.includes(id);
  };

  const handleOpenConditionalOffer = (offer: Offer) => {
    setOfferCondition(offer);
    setConditionModalOpen(true);
  };

  return (
    <>
      <div className="content-layout offers">
        <div className="layout-options">
          <div className="filters">
            {/* <div className="filter-checkbox">
                        <Checkbox className="checkbox-active">Exclusive to BrandSwop</Checkbox>
                        <Checkbox className="checkbox-active">Bookmarks</Checkbox>
                    </div>
                    <div className="filter-dropdown">
                        <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                            return <div className="dropdown">
                                <div className="dropdown-title">TOP 5 categories</div>
                                <ul>
                                    <li>
                                        <Checkbox className="checkbox-active">Clothing<span className="checkbox-value">6</span></Checkbox>
                                    </li>
                                    <li>
                                        <Checkbox defaultChecked={true} className="checkbox-active">Food<span className="checkbox-value">12</span></Checkbox>
                                    </li>
                                    <li>
                                        <Checkbox className="checkbox-active">Artificial intelligence<span className="checkbox-value">7</span></Checkbox>
                                    </li>
                                </ul>
                            </div>
                        }}>
                            <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                                Cateogries:
                                <span className="label-count">
                                    2
                                </span>
                            </span>
                        </Dropdown>

                        <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                            return <div className="dropdown">
                                <div className="dropdown-title">Offer type</div>
                                <ul>
                                    <li>
                                        <Checkbox defaultChecked={true} className="checkbox-active">Promotions<span className="checkbox-value">20</span></Checkbox>
                                    </li>
                                    <li>
                                        <Checkbox className="checkbox-active">Vouchers<span className="checkbox-value">12</span></Checkbox>
                                    </li>
                                </ul>
                            </div>
                        }}>
                            <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                                Types
                            </span>
                        </Dropdown>
                    </div> */}
          </div>
          <div className="views">
            {/* <span className="filter-icon list active"></span>
                    <span className="filter-icon grid"></span> */}
          </div>
        </div>

        <div className="table-wrapper table-scoll-wrapper">
          <div className="table-title flex-space-between">
            <span>Offers selected: {selectedOffers.length}</span>
            <Button
              type="primary"
              disabled={selectedOffers.length === 0}
              className="button-semi-active button-small mbot20"
              onClick={() => setSlotModalOpen(true)}
            >
              {" "}
              Manage slots
            </Button>
          </div>
          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>Slot - Percentage</th>
                  <th>Partner</th>
                  <th>Description</th>
                  <th>
                    CPA{" "}
                    <InfoCircleOutlined
                      className="font-hover"
                      id="guide-selected-deals-cpa"
                    />
                  </th>
                  <th>
                    Status{" "}
                    <InfoCircleOutlined
                      className="font-hover"
                      id="guide-selected-deals-status"
                    />
                  </th>
                  <th>End date</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {selectedOffers.map((offer: Offer, index: number) => {
                  return (
                    <tr key={`selected-offers${index}`}>
                      <td className="td-150">
                        {offer?.slot?.slot || "not set"} -{" "}
                        {`${offer?.slot?.percentage || 100}%` || "not set"}
                      </td>
                      <td className="td-250">
                        <span className="flex">
                          <img
                            className="table-image table-image-big mright10"
                            src={
                              offer.imageUrl ? offer.imageUrl : defaultOfferImg
                            }
                            alt=""
                          />
                          <span className="break-word">
                            {offer.advertiser.name}
                          </span>
                        </span>
                      </td>
                      {/* <td className="break-word">
                                        <Tooltip title={offer.description.length > 50 && selectedTriggers.includes(offer.id) ? offer.description : ''} >
                                            <span className="font-color">{selectedTriggers.includes(offer.id) ? sliceText(offer.description) : offer.description}</span>
                                            {offer.description.length > 100 && selectedTriggers.includes(offer.id) &&
                                                <span className="font-active font-hover" onClick={() => removeSelectedTriggerById(offer.id)}>...</span>
                                            }
                                        </Tooltip>
                                    </td> */}
                      <td className="break-word">
                        <span className="semibold">{offer.title}</span>
                        <span className="font-color max-4lines">
                          {offer.description}
                        </span>
                      </td>
                      <td>{offer.offerCpa.display}</td>
                      {/* <td>
                                        {stringDateToMoment(offer.startDate).format("DD-MM-YYYY")} {stringDateToMoment(offer.endDate).format("DD-MM-YYYY")}
                                    </td> */}
                      {/* <td>
                                        {offer.type}
                                    </td> */}
                      {/* <td>
                                        {!offer?.exclusiveTo?.regions?.length ? 'All' : offer?.exclusiveTo?.regions?.map((region: Country, index: number, arr: Country[]) => {
                                            return index !== arr.length - 1 ? `${region.name}, ` : region.name
                                        })}
                                    </td> */}
                      <td
                        className={
                          offer?.displayStatus === "Not Active"
                            ? "not-active"
                            : ""
                        }
                      >
                        {offer?.displayStatus || ""}
                      </td>
                      <td>
                        {stringDateToMoment(offer.endDate).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <span className="table-actions">
                          <span
                            className="table-icon-bordered mright10"
                            onClick={() => handleOpenConditionalOffer(offer)}
                          >
                            <ToolOutlined rotate={270} />
                          </span>
                          <span
                            className="table-icon-bordered"
                            onClick={() => openConfirmModal(offer.id)}
                          >
                            <DeleteOutlined />
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}

                {selectedOffers.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No offers"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="table-wrapper table-wrapper-tabs table-scrollable-wrapper">
          <ul className="content-list mbot30">
            <li
              className={activeClass === "partner" ? "active" : ""}
              onClick={() => {
                setActiveClass("partner");
                setFilters({ ...filters, type: OFFER_TYPES.advertiser });
              }}
            >
              Partner rewards
            </li>
            <li
              className={activeClass === "myoffers" ? "active" : ""}
              onClick={() => {
                setActiveClass("myoffers");
                setFilters({ ...filters, type: OFFER_TYPES.host });
              }}
            >
              My offers
            </li>
          </ul>

          <div className="table-title">
            Offers available: {response?.result?.count || 0}
          </div>

          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>Partner</th>
                  <th
                    onClick={() =>
                      setFilters((prevState) => ({
                        ...prevState,
                        offerSort: {
                          columnName: "title",
                          ascending: !prevState.offerSort.ascending,
                        },
                      }))
                    }
                    className={`table-filter ${
                      filters.offerSort.columnName === "title"
                        ? filters.offerSort.ascending
                          ? "down"
                          : "up"
                        : ""
                    }`}
                  >
                    Description
                  </th>
                  <th>
                    CPA{" "}
                    <InfoCircleOutlined
                      className="font-hover"
                      id="guide-approved-deals-cpa"
                    />
                  </th>
                  <th>
                    Status{" "}
                    <InfoCircleOutlined
                      className="font-hover"
                      id="guide-approved-deals-status"
                    />
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {company?.userAccess?.access.includes(
                  ACCESS_TYPES.offerread
                ) ? (
                  <>
                    {offers.map((offer: Offer, index: number) => {
                      return (
                        <tr key={`offer-${index}`}>
                          <td className="td-250">
                            <span className="flex">
                              <img
                                className="table-image table-image-big mright10"
                                src={
                                  offer.imageUrl
                                    ? offer.imageUrl
                                    : defaultOfferImg
                                }
                                alt=""
                              />
                              <span className="break-word">
                                {offer.advertiser.name}
                              </span>
                            </span>
                          </td>
                          <td className="break-word">
                            <span className="semibold">{offer.title}</span>
                            <span className="font-color max-4lines">
                              {offer.description}
                            </span>
                          </td>
                          <td>{offer.offerCpa.display}</td>
                          <td>{offer?.displayStatus || ""}</td>
                          <td>
                            <span className="table-actions">
                              {checkOfferSelected(offer.id) ? (
                                <span className="label label-select active table-label">
                                  Selected
                                </span>
                              ) : (
                                <span
                                  className={`label label-select table-label`}
                                  onClick={() => handleSelect(offer)}
                                >
                                  Select
                                </span>
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    {loading ? (
                      <tr
                        className={offers.length > 0 ? "loader-absolute" : ""}
                      >
                        <td className="text-center" colSpan={6}>
                          <LoadingOutlined />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {offers.length === 0 && (
                          <tr>
                            <td className="text-center" colSpan={6}>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No offers"
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <tr>
                      <td className="text-center" colSpan={6}>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="You are not authorized to access this resourse"
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="pagination">
          <div
            className="pagination-prev"
            onClick={() =>
              pagination.page > 1 &&
              setPagination((prevState) => ({
                ...prevState,
                page: prevState.page - 1,
              }))
            }
          >
            <span className="pagination-box"></span>
            <span className="pagination-label">Preview</span>
          </div>

          <ul>
            <>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page: number) => {
                  if (
                    (page < pagination.page + 3 &&
                      page > pagination.page - 3) ||
                    page === 1 ||
                    page === totalPages
                  ) {
                    return (
                      <React.Fragment key={page}>
                        {" "}
                        {page > 2 && page + 2 <= pagination.page && (
                          <span className="icon icon-pagination"></span>
                        )}
                        <li
                          onClick={() =>
                            page !== pagination.page &&
                            setPagination((prevState) => ({
                              ...prevState,
                              page,
                            }))
                          }
                          className={page === pagination.page ? "active" : ""}
                        >
                          <span className="pagination-box">{page}</span>
                        </li>
                        {page + 1 < totalPages &&
                          page - 2 >= pagination.page && (
                            <span className="icon icon-pagination"></span>
                          )}
                      </React.Fragment>
                    );
                  }
                  return <React.Fragment key={`rf${page}`}></React.Fragment>;
                }
              )}
            </>
          </ul>

          <div
            className="pagination-next"
            onClick={() =>
              pagination.page < totalPages &&
              setPagination((prevState) => ({
                ...prevState,
                page: prevState.page + 1,
              }))
            }
          >
            <span className="pagination-label">Next</span>
            <span className="pagination-box"></span>
          </div>
        </div>
      </div>

      {offerCondition && (
        <ModalOfferSettings
          campaign={campaign}
          isConditionModalOpen={isConditionModalOpen}
          setConditionModalOpen={setConditionModalOpen}
          offer={offerCondition}
          setOffer={setOfferCondition}
          setSelectedOffers={setSelectedOffers}
        />
      )}
      <ModalOfferSlots
        isOpen={isSlotModalOpen}
        setOpen={setSlotModalOpen}
        selectedOffers={selectedOffers}
        setSelectedOffers={setSelectedOffers}
      />
    </>
  );
};

export default CampaignOffers;
